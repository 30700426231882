<template>
  <div>
    <div class="bg-white cust_card h-full p-1" >
      <div class="">
        <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder  relative mt-2 bg-white">
          <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6 py-1 items-center" style="position: sticky;top: 0;background: white;z-index: 10;">
              <div class="asin_col h-16 flex items-center sticky left-0 bg-white" >
                <span class="cursor-pointer" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col h-16  flex items-center sticky bg-white" style="left: 190px !important;">
                <span class="cursor-pointer" @click="sortByColumn(1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="large_col h-16  flex items-center sticky bg-white" style="left: 330px !important;">
                <span class="cursor-pointer" @click="sortByColumn(2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="totalQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(3, titleObj[3].sort, titleObj[3].value)">{{titleObj[3].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="defQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(4, titleObj[4].sort, titleObj[4].value)">{{titleObj[4].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="expQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(5, titleObj[5].sort, titleObj[5].value)">{{titleObj[5].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="fulfilQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(6, titleObj[6].sort, titleObj[6].value)">{{titleObj[6].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[6].value) && titleObj[6].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[6].value) && !titleObj[6].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="inWorkQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(7, titleObj[7].sort, titleObj[7].value)">{{titleObj[7].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[7].value) && titleObj[7].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[7].value) && !titleObj[7].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="inShipQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(8, titleObj[8].sort, titleObj[8].value)">{{titleObj[8].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[8].value) && titleObj[8].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[8].value) && !titleObj[8].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="inRecQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(9, titleObj[9].sort, titleObj[9].value)">{{titleObj[9].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[9].value) && titleObj[9].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[9].value) && !titleObj[9].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="totResQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(10, titleObj[10].sort, titleObj[10].value)">{{titleObj[10].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[10].value) && titleObj[10].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[10].value) && !titleObj[10].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(11, titleObj[11].sort, titleObj[11].value)">{{titleObj[11].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[11].value) && titleObj[11].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[11].value) && !titleObj[11].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(12, titleObj[12].sort, titleObj[12].value)">{{titleObj[12].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[12].value) && titleObj[12].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[12].value) && !titleObj[12].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="fcProQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(13, titleObj[13].sort, titleObj[13].value)">{{titleObj[13].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[13].value) && titleObj[13].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[13].value) && !titleObj[13].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="totUnfQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(14, titleObj[14].sort, titleObj[14].value)">{{titleObj[14].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[14].value) && titleObj[14].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[14].value) && !titleObj[14].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="custDamQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(15, titleObj[15].sort, titleObj[15].value)">{{titleObj[15].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[15].value) && titleObj[15].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[15].value) && !titleObj[15].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(16, titleObj[16].sort, titleObj[16].value)">{{titleObj[16].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[16].value) && titleObj[16].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[16].value) && !titleObj[16].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(17, titleObj[17].sort, titleObj[17].value)">{{titleObj[17].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[17].value) && titleObj[17].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[17].value) && !titleObj[17].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="carDamQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(18, titleObj[18].sort, titleObj[18].value)">{{titleObj[18].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[18].value) && titleObj[18].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[18].value) && !titleObj[18].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(20, titleObj[20].sort, titleObj[20].value)">{{titleObj[20].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[20].value) && titleObj[20].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[20].value) && !titleObj[20].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
            </div>
            <div :style="`max-height:` + cardHight + `px !important;`" class="" v-if="reportListing.length > 0">
              <div class="min-w-min group flex border-r border-l border-b p-2 border-gray1 bg-white text-sm items-center py-1  hover:bg-blue-50"  v-for="(value, indexA) in reportListing" :key="indexA">
                <div class="asin_col cursor-pointer sticky left-0 bg-white group-hover:bg-blue-50 flex items-center">
                  <div class="w-16">
                    <img v-if="value.asinImgThumbnail !== ''" class="border border-gray-100 rounded-xl bg-white" style="height: 42px;width:80px;object-fit: contain;" :src="value.asinImgThumbnail">
                    <img v-else class="border border-gray-100 rounded-xl px-2 bg-white" style="height: 40px;object-fit: contain;" src="@/assets/default.png">
                  </div>
                  <span class="heading-6-1 text-text1 pl-2 showOnly3Line" v-if="value.asin !== ''">{{value.asin}}</span>
                  <span class="heading-6-1 text-text1 pl-2" v-else>--</span>
                </div>
                <div class="small_col  flex items-center sticky bg-white group-hover:bg-blue-50" style="left: 190px !important;">
                  <span class="heading-6-1 text-text1" v-if="value.sku !== ''">{{value.sku}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="large_col sticky bg-white group-hover:bg-blue-50" style="left: 330px !important;">
                  <span class="heading-6-1 text-text1">
                    <span class="mb-0 whitespace-pre-line">
                      {{value.description === '' ? '--' : (value.description.length > 133)? value.isDescViewM ?  value.description : value.description.substr(0, 133) : value.description}}
                      <span v-if="value.description.length > 133" class="text-primary cursor-pointer heading-7" @click="value.isDescViewM = !value.isDescViewM">
                        {{value.isDescViewM === false ? 'Read More...' : 'Read Less...'}}</span>
                    </span>
                  </span>
                </div>
                <div class="totalQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvTotalQuantity}}</span>
                </div>
                <div class="defQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvDefectiveQuantity}}</span>
                </div>
                <div class="expQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvExpiredQuantity}}</span>
                </div>
                <div class="fulfilQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvFulfillableQuantity}}</span>
                </div>
                <div class="inWorkQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvInboundWorkingQuantity}}</span>
                </div>
                <div class="inShipQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvInboundShippedQuantity}}</span>
                </div>
                <div class="inRecQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvInboundReceivingQuantity}}</span>
                </div>
                <div class="totResQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvTotalReservedQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvPendingCustomerOrderQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvPendingTransShipmentQuantity}}</span>
                </div>
                <div class="fcProQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvFcProcessingQuantity}}</span>
                </div>
                <div class="totUnfQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvTotalUnfulfillableQuantity}}</span>
                </div>
                <div class="custDamQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvCustomerDamagedQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvWarehouseDamagedQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvDistributorDamagedQuantity}}</span>
                </div>
                <div class="carDamQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvCarrierDamagedQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvLastUpdatedTime !== '' ? value.fbaInvLastUpdatedTime : '--'}}</span>
                </div>
              </div>
            </div>
          </div>
            <div class="layout m-2" v-if="reportListing.length === 0">
              <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  <p class=" font-medium heading-4">No record found.</p>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
</template>
<script>
import deboucneMixin from '@/mixins/debounce.js'
export default {
  mixins: [deboucneMixin],
  components: {
  },
  data () {
    return {
      titleObj: [
        {title: 'ASIN', icon: '', value: 'asin', sort: true},
        {title: 'SKU', icon: '', value: 'sku', sort: true},
        {title: 'Description', icon: '', value: 'description', sort: true},
        {title: 'Total Qty.', icon: '', value: 'fbaInvTotalQuantity', sort: true},
        {title: 'Defective Qty.', icon: '', value: 'fbaInvDefectiveQuantity', sort: true},
        {title: 'Expired Qty.', icon: '', value: 'fbaInvExpiredQuantity', sort: true},
        {title: 'Fulfilable Qty.', icon: '', value: 'fbaInvFulfillableQuantity', sort: true},
        {title: 'Inbound Working Qty.', icon: '', value: 'fbaInvInboundWorkingQuantity', sort: true},
        {title: 'Inbound Shipped Qty.', icon: '', value: 'fbaInvInboundShippedQuantity', sort: true},
        {title: 'Inbound Receiving Qty.', icon: '', value: 'fbaInvInboundReceivingQuantity', sort: true},
        {title: 'Total Reserved Qty.', icon: '', value: 'fbaInvTotalReservedQuantity', sort: true},
        {title: 'Pending Customer Order Qty.', icon: '', value: 'fbaInvPendingCustomerOrderQuantity', sort: true},
        {title: 'Pending Trans.Shipment Qty.', icon: '', value: 'fbaInvPendingTransShipmentQuantity', sort: true},
        {title: 'FC Processing Qty.', icon: '', value: 'fbaInvFcProcessingQuantity', sort: true},
        {title: 'Total Unfulfillable Qty.', icon: '', value: 'fbaInvTotalUnfulfillableQuantity', sort: true},
        {title: 'Customer Damaged Qty.', icon: '', value: 'fbaInvCustomerDamagedQuantity', sort: true},
        {title: 'Warehouse Damage Qty.', icon: '', value: 'fbaInvWarehouseDamagedQuantity', sort: true},
        {title: 'Distributor Damaged Qty.', icon: '', value: 'fbaInvDistributorDamagedQuantity', sort: true},
        {title: 'Carrier Damaged Qty.', icon: '', value: 'fbaInvCarrierDamagedQuantity', sort: true},
        {title: 'Product Name', icon: '', value: 'fbaInvProductName', sort: true},
        {title: 'Last Updated Time', icon: 'DESC', value: 'fbaInvLastUpdatedTime', sort: true},
        
      ],
      pagination: {
        sortBy: 'fbaInvLastUpdatedTime',
        descending: true
      },
      cardHight: 0,
      mainHeight: 0,
    }
  },
  props: ['reportListing', 'firstLoading'],
  created() {
  },
  mounted () {
    this.cardHight = window.innerHeight - 221
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    document.title = 'COGS ◾ Amazon Sage'
  },
  watch: {
  },
  methods: {
    sortByColumn (i, val1, val2) {
      console.log('true---------------->')
      this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait ...'})
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      if (this.pagination.descending) {
        this.reportListing.sort(function (a, b) {
          if (val2 === 'asin') {
            var nameA = a.asin
            var nameB = b.asin
          } else if (val2 === 'sku') {
            nameA = a.sku
            nameB = b.sku
          } else if (val2 === 'description') {
            nameA = a.description
            nameB = b.description
          } else if (val2 === 'fbaInvTotalQuantity') {
            nameA = a.fbaInvTotalQuantity
            nameB = b.fbaInvTotalQuantity
          } else if (val2 === 'fbaInvDefectiveQuantity') {
            nameA = a.fbaInvDefectiveQuantity
            nameB = b.fbaInvDefectiveQuantity
          } else if (val2 === 'fbaInvExpiredQuantity') {
            nameA = a.fbaInvExpiredQuantity
            nameB = b.fbaInvExpiredQuantity
          } else if (val2 === 'fbaInvFulfillableQuantity') {
            nameA = a.fbaInvFulfillableQuantity
            nameB = b.fbaInvFulfillableQuantity
          } else if (val2 === 'fbaInvInboundWorkingQuantity') {
            nameA = a.fbaInvInboundWorkingQuantity
            nameB = b.fbaInvInboundWorkingQuantity
          } else if (val2 === 'fbaInvInboundShippedQuantity') {
            nameA = a.fbaInvInboundShippedQuantity
            nameB = b.fbaInvInboundShippedQuantity
          } else if (val2 === 'fbaInvInboundReceivingQuantity') {
            nameA = a.fbaInvInboundReceivingQuantity
            nameB = b.fbaInvInboundReceivingQuantity
          } else if (val2 === 'fbaInvTotalReservedQuantity') {
            nameA = a.fbaInvTotalReservedQuantity
            nameB = b.fbaInvTotalReservedQuantity
          } else if (val2 === 'fbaInvPendingCustomerOrderQuantity') {
            nameA = a.fbaInvPendingCustomerOrderQuantity
            nameB = b.fbaInvPendingCustomerOrderQuantity
          } else if (val2 === 'fbaInvPendingTransShipmentQuantity') {
            nameA = a.fbaInvPendingTransShipmentQuantity
            nameB = b.fbaInvPendingTransShipmentQuantity
          } else if (val2 === 'fbaInvFcProcessingQuantity') {
            nameA = a.fbaInvFcProcessingQuantity
            nameB = b.fbaInvFcProcessingQuantity
          } else if (val2 === 'fbaInvTotalUnfulfillableQuantity') {
            nameA = a.fbaInvTotalUnfulfillableQuantity
            nameB = b.fbaInvTotalUnfulfillableQuantity
          } else if (val2 === 'fbaInvCustomerDamagedQuantity') {
            nameA = a.fbaInvCustomerDamagedQuantity
            nameB = b.fbaInvCustomerDamagedQuantity
          } else if (val2 === 'fbaInvWarehouseDamagedQuantity') {
            nameA = a.fbaInvWarehouseDamagedQuantity
            nameB = b.fbaInvWarehouseDamagedQuantity
          } else if (val2 === 'fbaInvDistributorDamagedQuantity') {
            nameA = a.fbaInvDistributorDamagedQuantity
            nameB = b.fbaInvDistributorDamagedQuantity
          } else if (val2 === 'fbaInvCarrierDamagedQuantity') {
            nameA = a.fbaInvCarrierDamagedQuantity
            nameB = b.fbaInvCarrierDamagedQuantity
          } else if (val2 === 'fbaInvProductName') {
            nameA = a.fbaInvProductName
            nameB = b.fbaInvProductName
          } else if (val2 === 'fbaInvLastUpdatedTime') {
            nameA = a.fbaInvLastUpdatedTime
            nameB = b.fbaInvLastUpdatedTime
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        this.reportListing.reverse(function (a, b) {
          if (val2 === 'asin') {
            var nameA = a.asin
            var nameB = b.asin
          } else if (val2 === 'sku') {
            nameA = a.sku
            nameB = b.sku
          } else if (val2 === 'description') {
            nameA = a.description
            nameB = b.description
          } else if (val2 === 'fbaInvTotalQuantity') {
            nameA = a.fbaInvTotalQuantity
            nameB = b.fbaInvTotalQuantity
          } else if (val2 === 'fbaInvDefectiveQuantity') {
            nameA = a.fbaInvDefectiveQuantity
            nameB = b.fbaInvDefectiveQuantity
          } else if (val2 === 'fbaInvExpiredQuantity') {
            nameA = a.fbaInvExpiredQuantity
            nameB = b.fbaInvExpiredQuantity
          } else if (val2 === 'fbaInvFulfillableQuantity') {
            nameA = a.fbaInvFulfillableQuantity
            nameB = b.fbaInvFulfillableQuantity
          } else if (val2 === 'fbaInvInboundWorkingQuantity') {
            nameA = a.fbaInvInboundWorkingQuantity
            nameB = b.fbaInvInboundWorkingQuantity
          } else if (val2 === 'fbaInvInboundShippedQuantity') {
            nameA = a.fbaInvInboundShippedQuantity
            nameB = b.fbaInvInboundShippedQuantity
          } else if (val2 === 'fbaInvInboundReceivingQuantity') {
            nameA = a.fbaInvInboundReceivingQuantity
            nameB = b.fbaInvInboundReceivingQuantity
          } else if (val2 === 'fbaInvTotalReservedQuantity') {
            nameA = a.fbaInvTotalReservedQuantity
            nameB = b.fbaInvTotalReservedQuantity
          } else if (val2 === 'fbaInvPendingCustomerOrderQuantity') {
            nameA = a.fbaInvPendingCustomerOrderQuantity
            nameB = b.fbaInvPendingCustomerOrderQuantity
          } else if (val2 === 'fbaInvPendingTransShipmentQuantity') {
            nameA = a.fbaInvPendingTransShipmentQuantity
            nameB = b.fbaInvPendingTransShipmentQuantity
          } else if (val2 === 'fbaInvFcProcessingQuantity') {
            nameA = a.fbaInvFcProcessingQuantity
            nameB = b.fbaInvFcProcessingQuantity
          } else if (val2 === 'fbaInvTotalUnfulfillableQuantity') {
            nameA = a.fbaInvTotalUnfulfillableQuantity
            nameB = b.fbaInvTotalUnfulfillableQuantity
          } else if (val2 === 'fbaInvCustomerDamagedQuantity') {
            nameA = a.fbaInvCustomerDamagedQuantity
            nameB = b.fbaInvCustomerDamagedQuantity
          } else if (val2 === 'fbaInvWarehouseDamagedQuantity') {
            nameA = a.fbaInvWarehouseDamagedQuantity
            nameB = b.fbaInvWarehouseDamagedQuantity
          } else if (val2 === 'fbaInvDistributorDamagedQuantity') {
            nameA = a.fbaInvDistributorDamagedQuantity
            nameB = b.fbaInvDistributorDamagedQuantity
          } else if (val2 === 'fbaInvCarrierDamagedQuantity') {
            nameA = a.fbaInvCarrierDamagedQuantity
            nameB = b.fbaInvCarrierDamagedQuantity
          } else if (val2 === 'fbaInvProductName') {
            nameA = a.fbaInvProductName
            nameB = b.fbaInvProductName
          } else if (val2 === 'fbaInvLastUpdatedTime') {
            nameA = a.fbaInvLastUpdatedTime
            nameB = b.fbaInvLastUpdatedTime
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      setTimeout(() => {
        this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait ...'})
      }, 300);
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
  },
  destroyed() {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>

.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.xmsmall_col {
  min-width: 70px !important;
  width: 70px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.avgSell_col {
  min-width: 80px !important;
  width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.val1_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.val_col {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallC_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

}
.small_col {
  min-width: 140px !important;
  width: 140px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

}
.asin_col {
  min-width: 190px !important;
  width: 190px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.mid_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.largeA_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.large_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallB_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallD_col {
  min-width: 170px !important;
  width: 170px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;

}
.smallA_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.Xlarge_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.regular_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.desc_col {
  min-width: 210px !important;
  width: 210px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.lableWidth {
  width: 130px;
}
.dotwidth {
  width: 15px;
}
.defQty {
  min-width: 89px !important;
  width: 89px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.expQty {
  min-width: 75px !important;
  width: 75px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.totalQty {
  min-width: 62px !important;
  width: 62px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.fulfilQty {
  min-width: 88px !important;
  width: 88px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.inWorkQty {
  min-width: 144px !important;
  width: 144px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.inShipQty {
  min-width: 138px !important;
  width: 138px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.inRecQty {
  min-width: 151px !important;
  width: 151px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.totResQty {
  min-width: 121px !important;
  width: 121px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.fcProQty {
  min-width: 111px !important;
  width: 111px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.totUnfQty {
  min-width: 142px !important;
  width: 142px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.custDamQty {
  min-width: 155px !important;
  width: 155px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.carDamQty {
  min-width: 135px !important;
  width: 135px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
</style>