<template>
  <div>
    <div class="bg-white cust_card h-full" style="position: fixed;width: 100%;min-width: 80px;z-index: 1000;top: 0;overflow-x: hidden;transition: 0.5s;border-radius: 0px !important;right:0;">
      <div class="rounded px-5 py-3  bg-primary2 text-white heading-2  flex items-center justify-between">
        <div class="flex items-center">
          <p class="text-white heading-4 pr-2 font-semibold">ASIN</p>
            <div v-if="fromApiStartDate !== '' && fromApiEndDate !== '' && !isSelectCustom" class="flex items-center">
                <p class="text-white heading-5  pt-0.5 font-normal">
                  <span class="text-white heading-5 font-normal">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-white heading-5 font-normal">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-white mt-0.5">
                  {{this.selectedValueinText}}
                </div>
              </div>
              <div v-if="dateDiffCount !== '' && isSelectCustom" class="flex items-center">
                <p class="text-white heading-5 pt-0.5 font-normal">
                  <span class="text-white heading-5 font-normal">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-white heading-5 font-normal">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-white mt-0.5">
                  {{this.dateDiffCount}} Days
                </div>
              </div>
          </div>
          <div class="flex gap-2">
            <div class=" text-white cursor-pointer hover:underline"  @click="exportReport()" v-if="firstLoading && activeIndex === 0">
              <button  style="letter-spacing: 1px;" class=" px-3 py-1 cust_card heading-6 mr-1" :class="zeroSalesReport.length > 0 ? 'bg-white text-primary cursor-pointer' : 'cursor-not-allowed bg-gray-300 opacity-60 text-text2'">Export to Excel</button>
            </div>
            <div class=" text-white cursor-pointer hover:underline" @click="closePopup()">
              <button style="letter-spacing: 1px;" class="bg-white text-primary px-3 py-1 cust_card heading-6 "><i class="fas fa-times"></i></button>
            </div>
          </div>  
      </div>
      <div class="rounded bg-white">
        <div class="mb-2">
          <TabCompo @setNewTabActive="tabsSet" :tabsList="tabs" />
        </div>
      </div>
      <div class="rounded bg-white">
        <div v-show="activeIndex === 0">
          <zeroSales :firstLoading="firstLoading" :reportListing="zeroSalesReport"></zeroSales>
        </div>
        <div v-show="activeIndex === 1">
          <noSalesVue :firstLoading="firstLoading" :reportListing="noSalesReport"></noSalesVue>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import zeroSales from './cmpnts/zeroSales.vue'
import noSalesVue from './cmpnts/noSales.vue'
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
import TabCompo from '@/View/components/tabs.vue'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    TabCompo,
    zeroSales,
    noSalesVue,
  },
  data () {
    return {
      zeroSalesReport: [],
      noSalesReport: [],
      firstLoading: false,
      fromApiStartDate: '',
      fromApiEndDate: '',
      pagination: {
        sortBy: 'asin',
        descending: true
      },
      dateDiffCount: 0,
      filterObject: null,
      activeIndex: 0,
      tabs: [
        {name: 'Zero Sales', active: true},
        {name: 'Not Sold', active: false},
      ],
    }
  },
  props: ["startDate", "endDate", "isSelectCustom", "selectedValueinText", "adsCostOfTotalAdCostColumn", "advertisingCostInPercentage", "totalSalesOfTotalSalesColumn"],
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.$root.$on('callZeroASIN', (start, End, response) => {
      console.log('data 2222', start, End, response)
      this.filterObject = response
      this.apiCall(start, End)
    })
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    document.title = 'COGS ◾ Amazon Sage'
  },
  watch: {
  },
  methods: {
    apiCall (utcSTART, utcEND) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      API.ZeroSalesASINReport(
          utcSTART,
          utcEND,
          this.filterObject.sendItemId,
          false,
          response => {
            let zeroSArr = response.Data.zeroSalesList === null ? [] : response.Data.zeroSalesList
            for (let index = 0; index < zeroSArr.length; index++) {
              zeroSArr[index].newAddCost = zeroSArr[index].avgSellingPrice * response.advertisingCostInPercentage / 100
              zeroSArr[index].newAvgUnitNet = zeroSArr[index].avgSellingPrice - (zeroSArr[index].stdCOGS + zeroSArr[index].avgMktFee + zeroSArr[index].avgFbaFee + zeroSArr[index].avgFbmFee + zeroSArr[index].avgInboundFee + (zeroSArr[index].avgSellingPrice * response.advertisingCostInPercentage / 100) )
            }
            this.zeroSalesReport = zeroSArr
            let noSArr = response.Data.notSoldList === null ? [] : response.Data.notSoldList
            for (let index = 0; index < noSArr.length; index++) {
              noSArr[index].isDescViewM = false
            }
            this.noSalesReport = noSArr
            this.fromApiStartDate = response.fromDate
            this.fromApiEndDate = response.toDate
            this.firstLoading = true
            this.getDateCountBetween(response.fromDate, response.toDate)
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
    },
    closePopup () {
      this.$root.$emit('closeZeroPopup', false)
    },
    getDateCountBetween (startDate, endDate) {
      var date1 = new Date(startDate);
      var date2 = new Date(endDate);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      console.log('Difference_In_Time', Difference_In_Time,'----------', date2.getTime(), '-------------', date1.getTime())
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      this.dateDiffCount = Difference_In_Days === 0 ? 1 : Math.round(Difference_In_Days)
    },
    async exportReport () {
      if (this.firstLoading) {
        let utcSTART = moment(new Date(this.filterObject.startDate)).format("YYYY-MM-DD HH:mm:ss")
        let utcEND = moment(new Date(this.filterObject.endDate)).format("YYYY-MM-DD HH:mm:ss")
        if (this.filterObject.sendItemId.length === 0) {
          this.filterObject.sendItemId = []
        }
        let day1 = moment(this.filterObject.startDate).format("MM-DD-YY")
        let day2 = moment(this.filterObject.endDate).format("MM-DD-YY")
        let Time = ''
        let subURL = ''
        subURL = 'api/v1/analytics/amazon/zeroSales/sageASINReport'
        Time = 'ASIN - Report for 0 Sales (' + day1 + '-To-' + day2 + ')'
        let data = new FormData()
        data.append('fromDate', utcSTART)
        data.append('toDate', utcEND)
        data.append('productIds', this.filterObject.sendItemId)
        data.append('export', true)
        await this.downloadAttachment(subURL, `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    tabsSet (index) {
      this.tabs.forEach((element, i) => {
          if (index === i) {
              element.active = true
              this.activeIndex = i
          } else {
              element.active = false
          }
      });
    },
  },
  destroyed() {
  },
  beforeDestroy () {
    this.$root.$off('paginationData')
     this.$root.$off('callZeroASIN')
  }
}
</script>
<style scoped>

.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.xmsmall_col {
  min-width: 70px !important;
  width: 70px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.avgSell_col {
  min-width: 80px !important;
  width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.val1_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.val_col {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallC_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

}
.small_col {
  min-width: 140px !important;
  width: 140px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.mid_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.largeA_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.large_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallB_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallA_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.Xlarge_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.regular_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.desc_col {
  min-width: 390px !important;
  width: 390px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.lableWidth {
  width: 130px;
}
.dotwidth {
  width: 15px;
}
</style>
