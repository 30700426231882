<template>
  <div>
    <div class="bg-white cust_card h-full" style="position: fixed;width: 100%;min-width: 80px;z-index: 1000;top: 0;overflow-x: hidden;transition: 0.5s;border-radius: 0px !important;right:0;">
      <div class="rounded px-5 py-3  bg-primary2 text-white heading-2  flex items-center justify-between">
          <div class="flex items-center">
            <p class="font-bold">{{selObj}}</p>
            <div v-if="fromApiStartDate !== '' && fromApiEndDate !== '' && !isSelectCustom" class="flex items-center pl-4">
                <p class="text-white heading-5  pt-0.5 font-normal">
                  <span class="text-white heading-5 font-normal">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-white heading-5 font-normal">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-white mt-0.5">
                  {{this.selectedValueinText}}
                </div>
              </div>
              <div v-if="dateDiffCount !== '' && isSelectCustom" class="flex items-center pl-4">
                <p class="text-white heading-5 pt-0.5 font-normal">
                  <span class="text-white heading-5 font-normal">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-white heading-5 font-normal">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-white mt-0.5">
                  {{this.dateDiffCount}} Days
                </div>
              </div>
          </div>
          <div class="flex gap-2">
            <div class=" text-white cursor-pointer hover:underline"  @click="exportReport()">
              <button  style="letter-spacing: 1px;" class=" px-3 py-1 cust_card heading-6 " :class="detailArr.length > 0 ? 'bg-white text-primary cursor-pointer' : 'cursor-not-allowed bg-gray-300 opacity-60 text-text2'">Export to Excel</button>
            </div>
            <div class=" text-white cursor-pointer hover:underline" @click="closePopup()">
              <button style="letter-spacing: 1px;" class="bg-white text-primary px-3 py-1 cust_card heading-6 ">Close</button>
            </div>
          </div>  
      </div>
      <div class="">
        <div >
          <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder  relative mt-2 bg-white" v-if="detailArr.length > 0">
            <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6 py-1 items-center" style="position: sticky;top: 0;background: white;">
              <div class="mid_col  flex items-center" >
                <span class="cursor-pointer" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="asin_col  flex items-center">
                <span class="cursor-pointer" @click="sortByColumn(1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="mid_col  flex items-center" >
                <span class="cursor-pointer" @click="sortByColumn(2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="amz_description_col  flex items-center" >
                <span class="cursor-pointer" @click="sortByColumn(3, titleObj[3].sort, titleObj[3].value)">{{titleObj[3].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" >
                <span class="cursor-pointer" @click="sortByColumn(4, titleObj[4].sort, titleObj[4].value)">{{titleObj[4].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center " >
                <span class="cursor-pointer" @click="sortByColumn(5, titleObj[5].sort, titleObj[5].value)">{{titleObj[5].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="mid_col  flex items-center " >
                <span class="cursor-pointer" @click="sortByColumn(6, titleObj[6].sort, titleObj[6].value)">{{titleObj[6].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[6].value) && titleObj[6].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[6].value) && !titleObj[6].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" >
                <span class="cursor-pointer" @click="sortByColumn(7, titleObj[7].sort, titleObj[7].value)">{{titleObj[7].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[7].value) && titleObj[7].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[7].value) && !titleObj[7].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  flex items-center " >
                <span class="cursor-pointer" @click="sortByColumn(8, titleObj[8].sort, titleObj[8].value)">{{titleObj[8].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[8].value) && titleObj[8].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[8].value) && !titleObj[8].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(9, titleObj[9].sort, titleObj[9].value)">{{titleObj[9].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[9].value) && titleObj[9].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[9].value) && !titleObj[9].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" >
                <span class="cursor-pointer" @click="sortByColumn(10, titleObj[10].sort, titleObj[10].value)">{{titleObj[10].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[10].value) && titleObj[10].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[10].value) && !titleObj[10].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" >
                <span class="cursor-pointer" @click="sortByColumn(11, titleObj[11].sort, titleObj[11].value)">{{titleObj[11].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[11].value) && titleObj[11].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[11].value) && !titleObj[11].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" >
                <span >{{titleObj[12].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[12].value) && titleObj[12].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[12].value) && !titleObj[12].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" >
                <span class="cursor-pointer" @click="sortByColumn(13, titleObj[13].sort, titleObj[13].value)">{{titleObj[13].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[13].value) && titleObj[13].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[13].value) && !titleObj[13].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center " >
                <span class="cursor-pointer" @click="sortByColumn(14, titleObj[14].sort, titleObj[14].value)">{{titleObj[14].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[14].value) && titleObj[14].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[14].value) && !titleObj[14].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center " >
                <span class="cursor-pointer" @click="sortByColumn(15, titleObj[15].sort, titleObj[15].value)">{{titleObj[15].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[15].value) && titleObj[15].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[15].value) && !titleObj[15].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="mid_col  flex items-center " >
                <span class="cursor-pointer" @click="sortByColumn(16, titleObj[16].sort, titleObj[16].value)">{{titleObj[16].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[16].value) && titleObj[16].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[16].value) && !titleObj[16].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(17, titleObj[17].sort, titleObj[17].value)">{{titleObj[17].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[17].value) && titleObj[17].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[17].value) && !titleObj[17].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(18, titleObj[18].sort, titleObj[18].value)">{{titleObj[18].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[18].value) && titleObj[18].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[18].value) && !titleObj[18].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(19, titleObj[19].sort, titleObj[19].value)">{{titleObj[19].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[19].value) && titleObj[19].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[19].value) && !titleObj[19].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(20, titleObj[20].sort, titleObj[20].value)">{{titleObj[20].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[20].value) && titleObj[20].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[20].value) && !titleObj[20].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(21, titleObj[21].sort, titleObj[21].value)">{{titleObj[21].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[21].value) && titleObj[21].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[21].value) && !titleObj[21].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(22, titleObj[22].sort, titleObj[22].value)">{{titleObj[22].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[22].value) && titleObj[22].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[22].value) && !titleObj[22].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(23, titleObj[23].sort, titleObj[23].value)">{{titleObj[23].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[23].value) && titleObj[23].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[23].value) && !titleObj[23].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(24, titleObj[24].sort, titleObj[24].value)">{{titleObj[24].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[24].value) && titleObj[24].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[24].value) && !titleObj[24].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(25, titleObj[25].sort, titleObj[25].value)">{{titleObj[25].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[25].value) && titleObj[25].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[25].value) && !titleObj[25].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(26, titleObj[26].sort, titleObj[26].value)">{{titleObj[26].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[26].value) && titleObj[26].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[26].value) && !titleObj[26].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(27, titleObj[27].sort, titleObj[27].value)">{{titleObj[27].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[27].value) && titleObj[27].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[27].value) && !titleObj[27].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(28, titleObj[28].sort, titleObj[28].value)">{{titleObj[28].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[28].value) && titleObj[28].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[28].value) && !titleObj[28].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(29, titleObj[29].sort, titleObj[29].value)">{{titleObj[29].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[29].value) && titleObj[29].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[29].value) && !titleObj[29].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
             
        </div>
        <div :style="`max-height:` + cardHight + `px !important;`" class=""  v-if="detailArr.length > 0 ">
              <div class="min-w-min flex border-r border-l border-b p-2 border-gray1 bg-white text-sm items-center py-1  hover:bg-blue-50" :class="{'bg-gray-50': indexA % 2}"
              v-for="(value, indexA) in detailArr" :key="indexA">
                <div class="mid_col">
                  <span class="heading-6-1 text-text1" v-if="value.orderId !== ''">{{value.orderId}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                  <div class="asin_col cursor-pointer text-primary  flex items-center" @click="selectData(value.asin, indexA, 'asin')">
                    <div class="w-16">
                      <img v-if="value.asinThumbnail !== ''" class="border border-gray-100 rounded-xl bg-white" style="height: 42px;width:80px;object-fit: contain;" :src="value.asinThumbnail">
                      <img v-else class="border border-gray-100 rounded-xl px-2 bg-white" style="height: 40px;object-fit: contain;" src="@/assets/default.png">
                    </div>
                    <span class="heading-6-1 pl-2" v-if="value.asin !== ''">{{value.asin}}</span>
                    <span class="heading-6-1 pl-2" v-else>--</span>
                    <div class="relative">
                      <div v-if="storeIndex1 === indexA && isOpen" class="absolute w-64 -top-20 -right-56 card shadow-xl rounded-lg text-text1 hover:text-text1 bg-white " style="z-index: 2000 !important;">
                        <div class="px-4 py-2  bg-primary rounded text-white heading-4 font-bold flex items-center justify-between">
                          <p class="">{{selectedData}}</p>
                          <div class=" text-white cursor-pointer hover:underline" @click.stop="closeDialog()">
                            <button style="letter-spacing: 1px;" class="bg-white text-primary2 px-3 py-1 cust_card heading-6 "><i class="fas fa-times"></i></button>
                          </div>
                        </div>
                        <div class="p-2">
                          <div class="card rouned p-2 cursor-pointer hover:bg-gray1" @click="redirectToDetail('sku')">
                            <p class="heading-5 text-text1">Show Sku Report</p>
                          </div>
                          <div class="card rouned p-2 cursor-pointer hover:bg-gray1" @click="redirectToDetail('asin')">
                            <p class="heading-5 text-text1">Show ASIN Report</p>
                          </div>
                          <div class="card rouned p-2 cursor-pointer hover:bg-gray1" @click.stop="redirectNewTab('asin')">
                            <p class="heading-5 text-text1">Show ASIN in Amazon</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="mid_col cursor-pointer text-primary " @click="selectData(value.amazonSKU, indexA, 'SKU')">
                  <span class="heading-6-1" v-if="value.amazonSKU !== ''">{{value.amazonSKU}}</span>
                  <span class="heading-6-1" v-else>--</span>
                  <div class="relative">
                      <div v-if="storeIndex2 === indexA && isOpen" class="absolute w-64 -top-20 -right-56 card shadow-xl rounded-lg text-text1 hover:text-text1 bg-white " style="z-index: 2000 !important;">
                        <div class="px-4 py-2  bg-primary rounded text-white heading-4 font-bold flex items-center justify-between">
                          <p class="">{{selectedData}}</p>
                          <div class=" text-white cursor-pointer hover:underline" @click.stop="closeDialog()">
                            <button style="letter-spacing: 1px;" class="bg-white text-primary2 px-3 py-1 cust_card heading-6 "><i class="fas fa-times"></i></button>
                          </div>
                        </div>
                        <div class="p-2">
                          <div class="card rouned p-2 cursor-pointer hover:bg-gray1" @click="redirectToDetail('sku')">
                            <p class="heading-5 text-text1">Show Sku Report</p>
                          </div>
                          <div class="card rouned p-2 cursor-pointer hover:bg-gray1" @click.stop="redirectNewTab('sku')">
                            <p class="heading-5 text-text1">Show SKU in Amazon</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="amz_description_col">
                  <span class="heading-6-1 text-text1">
                    <span class="mb-0 whitespace-pre-line">
                      {{value.amazonDesc === '' ? '--' : (value.amazonDesc.length > 150)? value.isDescViewM ?  value.amazonDesc : value.amazonDesc.substr(0, 150) : value.amazonDesc}}
                      <span v-if="value.amazonDesc.length > 150" class="text-primary cursor-pointer heading-7" @click="value.isDescViewM = !value.isDescViewM">
                        {{value.isDescViewM === false ? 'Read More...' : 'Read Less...'}}</span>
                    </span>
                  </span>
                </div>
                <div class="small_col cursor-pointer text-primary " @click="selectData(value.sageItemCode, indexA, 'Code')">
                  <span class="heading-6-1" v-if="value.sageItemCode !== ''">{{value.sageItemCode}}</span>
                  <span class="heading-6-1" v-else>--</span>
                  <div class="relative">
                      <div v-if="storeIndex3 === indexA && isOpen" class="absolute w-64 -top-20 -right-56 card shadow-xl rounded-lg text-text1 hover:text-text1 bg-white " style="z-index: 2000 !important;">
                        <div class="px-4 py-2  bg-primary rounded text-white heading-4 font-bold flex items-center justify-between">
                          <p class="" >{{selectedData}}</p>
                          <div class=" text-white cursor-pointer hover:underline" @click.stop="closeDialog()">
                            <button style="letter-spacing: 1px;" class="bg-white text-primary2 px-3 py-1 cust_card heading-6 "><i class="fas fa-times"></i></button>
                          </div>
                        </div>
                        <div class="p-2">
                          <div class="card rouned p-2 cursor-pointer hover:bg-gray1" @click="redirectToDetail('sageItem')">
                            <p class="heading-5 text-text1">Show Sage item Report</p>
                          </div>
                          <div class="card rouned p-2 cursor-pointer hover:bg-gray1" @click="redirectToDetail('sku')">
                            <p class="heading-5 text-text1">Show Sku Report</p>
                          </div>
                          <div class="card rouned p-2 cursor-pointer hover:bg-gray1" @click="redirectToDetail('asin')">
                            <p class="heading-5 text-text1">Show ASIN Report</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="small_col">
                  <span class="heading-6-1 text-text1" v-if="value.sageItemDesc !== ''">{{value.sageItemDesc}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="mid_col">
                  <span class="heading-6-1 text-text1" v-if="value.orderDate !== ''">{{value.orderDate | dateConvertPdt}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="small_col">
                  <span class="heading-6-1 text-text1" v-if="value.settlementId !== ''">{{value.settlementId}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="total_col">
                  <span class="heading-6-1 text-text1" v-if="value.type !== ''">{{value.type}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1" v-if="value.quantity !== ''">{{value.quantity}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="small_col">
                  <span class="heading-6-1 text-text1" v-if="value.marketplace !== ''">{{value.marketplace}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="small_col">
                  <span class="heading-6-1 text-text1" v-if="value.accountType !== ''">{{value.accountType}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="small_col">
                  <span class="heading-6-1 text-text1" v-if="value.fullfillment !== ''">{{value.fullfillment}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="small_col">
                  <span class="heading-6-1 text-text1" v-if="value.orderCity !== ''">{{value.orderCity}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="small_col">
                  <span class="heading-6-1 text-text1" v-if="value.orderState !== ''">{{value.orderState}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="small_col">
                  <span class="heading-6-1 text-text1" v-if="value.orderPostal !== ''">{{value.orderPostal}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="mid_col">
                  <span class="heading-6-1 text-text1" v-if="value.taxCollectionModel !== ''">{{value.taxCollectionModel}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="small_col text-right justify-end">
                  <span class="heading-6-1 text-text1" >{{value.productSales  | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col text-right justify-end">
                  <span class="heading-6-1 text-text1" >{{value.productSalesTax  | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.shippingCreditsTax  | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col text-right justify-end">
                  <span class="heading-6-1 text-text1" >{{value.giftWrapCredits  | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col text-right justify-end">
                  <span class="heading-6-1 text-text1" >{{value.giftWrapCreditsTax  | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col text-right justify-end">
                  <span class="heading-6-1 text-text1" >{{value.promotionalRebates  | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col text-right justify-end ">
                  <span class="heading-6-1 text-text1">{{value.promotionalRebatesTax  | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col text-right justify-end">
                  <span class="heading-6-1 text-text1" >{{value.marketplaceWithheldTax  | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1" >{{value.sellingFees  | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaFees  | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.otherTransactionFees  | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.other  | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end" >
                  <span class="heading-6-1 text-text1" >{{value.total | amountFormaterWithToFix}}</span>
                </div>
                
              </div>
        </div>
          </div>
          <div class="layout m-2" v-if="detailArr.length === 0">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-1">
        <Pagination
          :isShowOption="true"
          :totalDataCount="totalCount"
          :resetPagination="resetPaginationValues"
          @callApiAsPerPagination="getCustomerDataFromPagination"/>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import deboucneMixin from '@/mixins/debounce.js'
import Pagination from '@/View/components/rowDataPagination.vue'
export default {
  mixins: [deboucneMixin],
  components: {
    Pagination,
  },
  data () {
    return {
      resetPaginationValues: false,
      titleObj: [
        {title: 'Order Id', icon: '', value: 'orderId', sort: true},
        {title: 'Asin', icon: '', value: 'asin', sort: true},
        {title: 'Sku', icon: '', value: 'amazonSKU', sort: true},
        {title: 'Description', icon: '', value: 'amazonDesc', sort: true},
        {title: 'Sage Item Code', icon: '', value: 'sageItemCode', sort: true},
        {title: 'Sage Item Desc', icon: '', value: 'sageItemDesc', sort: true},
        {title: 'Order Date(PDT)', icon: '', value: 'orderDate', sort: true},
        {title: 'Settlement Id', icon: '', value: 'settlementId', sort: true},
        {title: 'Order Type', icon: '', value: 'type', sort: true},
        {title: 'Quantity', icon: '', value: 'quantity', sort: true},
        {title: 'Marketplace', icon: '', value: 'marketplace', sort: true},
        {title: 'Account Type', icon: '', value: 'accountType', sort: true},
        {title: 'Fullfillment', icon: '', value: 'fullfillment', sort: true},
        {title: 'Order City', icon: '', value: 'orderCity', sort: true},
        {title: 'Order State', icon: '', value: 'orderState', sort: true},
        {title: 'Order Postal', icon: '', value: 'orderPostal', sort: true},
        {title: 'Tax Collection Model', icon: '', value: 'taxCollectionModel', sort: true},
        {title: 'Product Sales', icon: '', value: 'productSales', sort: true},
        {title: 'Product Sales Tax', icon: '', value: 'productSalesTax', sort: true},
        {title: 'Shipping Credits Tax', icon: '', value: 'shippingCreditsTax', sort: true},
        {title: 'Gift Wrap Credits', icon: '', value: 'giftWrapCredits', sort: true},
        {title: 'Gift Wrap Credits Tax', icon: '', value: 'giftWrapCreditsTax', sort: true},
        {title: 'Promotional Rebates', icon: '', value: 'promotionalRebates', sort: true},
        {title: 'Promotional Rebates Tax', icon: '', value: 'promotionalRebatesTax', sort: true},
        {title: 'Marketplace Withheld Tax', icon: '', value: 'marketplaceWithheldTax', sort: true},
        {title: 'Selling Fees', icon: '', value: 'sellingFees', sort: true},
        {title: 'Fba Fee', icon: '', value: 'fbaFees', sort: true},
        {title: 'Other Transaction Fees', icon: '', value: 'otherTransactionFees', sort: true},
        {title: 'Other', icon: '', value: 'other', sort: true},
        {title: 'Total', icon: '', value: 'total', sort: true},
      ],
      deleteConfirm: false,
      isFilterOpen: true,
      isRefresh: true,
      selectedArr: [],
      searchVal: '',
      searchLoader: false,
      selectAllCheck: false,
      itemListing: [],
      requiredDataListing: [],
      selectedValue: 'last30Days',
      pagination: {
        sortBy: 'amazonSKU',
        descending: true
      },
      buttonFiltersArray: [
        {
          title: 'Today',
          selected: false,
          value: 'today'
        },
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        {
          title: 'Last Week',
          selected: false,
          value: 'lastWeek'
        },
        {
          title: 'Last 7 Days',
          selected: false,
          value: 'thisWeek'
        },
        {
          title: 'Last Month',
          selected: false,
          value: 'lastMonth'
        },
        {
          title: 'Last 30 Days',
          selected: true,
          value: 'last30Days'
        },
        {
          title: 'Last 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Last 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Last 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Custom',
          selected: false,
          value: 'custom'
        }
      ],
      drag: false,
      tempArrA: [],
      filterObject: {
        startDate: '',
        endDate: '',
        sendItemId: [],
        minDate: '',
        maxDate: '',
      },
      selectedData: '',
      dataBase: [],
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      storeIndex1: -1,
      storeIndex2: -1,
      storeIndex3: -1,
      isOpen: false,
      defaultEndDate: '',
      showCustomDatepicker: false,
      getListingDetail: () => {},
      adsCostOfTotalAdCostColumn: 0,
      totalSalesOfTotalSalesColumn: 0,
    }
  },
  props: ["detailArr", "selObj", "isLoading", "totalCount", "advertisingCostInPercentage", "isSelectCustom", "fromApiStartDate", "fromApiEndDate", "dateDiffCount", "selectedValueinText", "activeIndex"],
  created() {
  },
  mounted () {
    console.log('selObj', this.selObj)
    console.log('detailArr', this.detailArr)
    this.cardHight = window.innerHeight - 221
    this.mainHeight = window.innerHeight - 90
  },
  methods: {
    closeDialog () {
      this.storeIndex1 = -1
      this.storeIndex2 = -1
      this.storeIndex3 = -1
      this.isOpen = false
    },
    selectData (data, storeIndex, from) {
      this.isOpen = true
      console.log('from', from)
      console.log('storeIndex', storeIndex)
      if (from === 'asin') {
        this.storeIndex1 = storeIndex
        this.storeIndex2 = -1
        this.storeIndex3 = -1
      } else if (from === 'SKU') {
        this.storeIndex1 = -1
        this.storeIndex2 = storeIndex
        this.storeIndex3 = -1
      } else if (from === 'Code') {
        this.storeIndex1 = -1
        this.storeIndex2 = -1
        this.storeIndex3 = storeIndex
      }
        this.selectedData = data
      },
      redirectNewTab (data) {
        if (data === 'asin') {
          let URL = 'https://www.amazon.com/dp/' + this.selectedData + '?ref=myi_title_dp'
          window.open(URL);
        } else if (data === 'sku') {
          let URL = 'https://sellercentral.amazon.com/skucentral?mSku=' + this.selectedData + '&ref=myi_skuc'
          window.open(URL);
        }
        this.storeIndex1 = -1
        this.storeIndex2 = -1
        this.storeIndex3 = -1
        this.isOpen = false
      },
      redirectToDetail (data) {
      this.storeIndex = ''
      //tab=sageItemCode&searchVal=ECP-HD-G-BAGS-H-150&from=2023-09-12 14:41:23&to=2023-10-11 14:41:23
      let url = ''
      let startDate = moment(new Date(this.fromApiStartDate)).format("YYYY-MM-DD HH:mm:ss")
      let endDate = moment(new Date(this.fromApiEndDate)).format("YYYY-MM-DD HH:mm:ss")
      if (data === 'sageItem') {
        url = this.$router.resolve({name: 'Amazon Sage', query: {tab: 0, searchValue:this.selectedData, from:startDate, to:endDate, selectedValueinText:this.selectedValueinText, isSelectCustom:this.isSelectCustom, dateDiffCount: this.dateDiffCount}})
      } else if (data === 'sku') {
        url = this.$router.resolve({name: 'Amazon Sage', query: {tab: 1, searchValue:this.selectedData, from:startDate, to:endDate, selectedValueinText:this.selectedValueinText, isSelectCustom:this.isSelectCustom, dateDiffCount: this.dateDiffCount}})
      } else if (data === 'asin') {
        url = this.$router.resolve({name: 'Amazon Sage', query: {tab: 2, searchValue:this.selectedData, from:startDate, to:endDate, selectedValueinText:this.selectedValueinText, isSelectCustom:this.isSelectCustom, dateDiffCount: this.dateDiffCount}})
      }
      window.open(url.href, '_blank')

    },
    getCustomerDataFromPagination (data) {
      console.log('data 123', data)
      this.$root.$emit('paginationData', data)
    },
    closePopup () {
      this.$root.$emit('closePopup', false)
    },
    sortByColumn (i, val1, val2) {
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      if (this.pagination.descending) {
        this.detailArr.sort(function (a, b) {
          if (val2 === 'orderId') {
            var nameA = a.orderId
            var nameB = b.orderId
          } else if (val2 === 'asin') {
            nameA = a.asin
            nameB = b.asin
          } else if (val2 === 'amazonDesc') {
            nameA = a.amazonDesc
            nameB = b.amazonDesc
          } else if (val2 === 'sageItemCode') {
            nameA = a.sageItemCode
            nameB = b.sageItemCode
          } else if (val2 === 'sageItemDesc') {
            nameA = a.sageItemDesc
            nameB = b.sageItemDesc
          } else if (val2 === 'orderDate') {
            nameA = a.orderDate
            nameB = b.orderDate
          } else if (val2 === 'settlementId') {
            nameA = a.settlementId
            nameB = b.settlementId
          } else if (val2 === 'type') {
            nameA = a.type
            nameB = b.type
          } else if (val2 === 'quantity') {
            nameA = a.quantity
            nameB = b.quantity
          } else if (val2 === 'marketplace') {
            nameA = a.marketplace
            nameB = b.marketplace
          } else if (val2 === 'accountType') {
            nameA = a.accountType
            nameB = b.accountType
          } else if (val2 === 'fullfillment') {
            nameA = a.fullfillment
            nameB = b.fullfillment
          } else if (val2 === 'orderCity') {
            nameA = a.orderCity
            nameB = b.orderCity
          } else if (val2 === 'orderState') {
            nameA = a.orderState
            nameB = b.orderState
          } else if (val2 === 'orderPostal') {
            nameA = a.orderPostal
            nameB = b.orderPostal
          } else if (val2 === 'taxCollectionModel') {
            nameA = a.taxCollectionModel
            nameB = b.taxCollectionModel
          } else if (val2 === 'productSales') {
            nameA = a.productSales
            nameB = b.productSales
          } else if (val2 === 'productSalesTax') {
            nameA = a.productSalesTax
            nameB = b.productSalesTax
          } else if (val2 === 'shippingCreditsTax') {
            nameA = a.shippingCreditsTax
            nameB = b.shippingCreditsTax
          } else if (val2 === 'giftWrapCredits') {
            nameA = a.giftWrapCredits
            nameB = b.giftWrapCredits
          } else if (val2 === 'giftWrapCreditsTax') {
            nameA = a.giftWrapCreditsTax
            nameB = b.giftWrapCreditsTax
          } else if (val2 === 'promotionalRebates') {
            nameA = a.promotionalRebates
            nameB = b.promotionalRebates
          } else if (val2 === 'promotionalRebatesTax') {
            nameA = a.promotionalRebatesTax
            nameB = b.promotionalRebatesTax
          } else if (val2 === 'marketplaceWithheldTax') {
            nameA = a.marketplaceWithheldTax
            nameB = b.marketplaceWithheldTax
          } else if (val2 === 'sellingFees') {
            nameA = a.sellingFees
            nameB = b.sellingFees
          } else if (val2 === 'fbaFees') {
            nameA = a.fbaFees
            nameB = b.fbaFees
          } else if (val2 === 'otherTransactionFees') {
            nameA = a.otherTransactionFees
            nameB = b.otherTransactionFees
          } else if (val2 === 'other') {
            nameA = a.other
            nameB = b.other
          } else if (val2 === 'total') {
            nameA = a.total
            nameB = b.total
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        this.detailArr.reverse(function (a, b) {
          if (val2 === 'orderId') {
            var nameA = a.orderId
            var nameB = b.orderId
          } else if (val2 === 'asin') {
            nameA = a.asin
            nameB = b.asin
          } else if (val2 === 'amazonDesc') {
            nameA = a.amazonDesc
            nameB = b.amazonDesc
          } else if (val2 === 'sageItemCode') {
            nameA = a.sageItemCode
            nameB = b.sageItemCode
          } else if (val2 === 'sageItemDesc') {
            nameA = a.sageItemDesc
            nameB = b.sageItemDesc
          } else if (val2 === 'orderDate') {
            nameA = a.orderDate
            nameB = b.orderDate
          } else if (val2 === 'settlementId') {
            nameA = a.settlementId
            nameB = b.settlementId
          } else if (val2 === 'type') {
            nameA = a.type
            nameB = b.type
          } else if (val2 === 'quantity') {
            nameA = a.quantity
            nameB = b.quantity
          } else if (val2 === 'marketplace') {
            nameA = a.marketplace
            nameB = b.marketplace
          } else if (val2 === 'accountType') {
            nameA = a.accountType
            nameB = b.accountType
          } else if (val2 === 'fullfillment') {
            nameA = a.fullfillment
            nameB = b.fullfillment
          } else if (val2 === 'orderCity') {
            nameA = a.orderCity
            nameB = b.orderCity
          } else if (val2 === 'orderState') {
            nameA = a.orderState
            nameB = b.orderState
          } else if (val2 === 'orderPostal') {
            nameA = a.orderPostal
            nameB = b.orderPostal
          } else if (val2 === 'taxCollectionModel') {
            nameA = a.taxCollectionModel
            nameB = b.taxCollectionModel
          } else if (val2 === 'productSales') {
            nameA = a.productSales
            nameB = b.productSales
          } else if (val2 === 'productSalesTax') {
            nameA = a.productSalesTax
            nameB = b.productSalesTax
          } else if (val2 === 'shippingCreditsTax') {
            nameA = a.shippingCreditsTax
            nameB = b.shippingCreditsTax
          } else if (val2 === 'giftWrapCredits') {
            nameA = a.giftWrapCredits
            nameB = b.giftWrapCredits
          } else if (val2 === 'giftWrapCreditsTax') {
            nameA = a.giftWrapCreditsTax
            nameB = b.giftWrapCreditsTax
          } else if (val2 === 'promotionalRebates') {
            nameA = a.promotionalRebates
            nameB = b.promotionalRebates
          } else if (val2 === 'promotionalRebatesTax') {
            nameA = a.promotionalRebatesTax
            nameB = b.promotionalRebatesTax
          } else if (val2 === 'marketplaceWithheldTax') {
            nameA = a.marketplaceWithheldTax
            nameB = b.marketplaceWithheldTax
          } else if (val2 === 'sellingFees') {
            nameA = a.sellingFees
            nameB = b.sellingFees
          } else if (val2 === 'fbaFees') {
            nameA = a.fbaFees
            nameB = b.fbaFees
          } else if (val2 === 'otherTransactionFees') {
            nameA = a.otherTransactionFees
            nameB = b.otherTransactionFees
          } else if (val2 === 'other') {
            nameA = a.other
            nameB = b.other
          } else if (val2 === 'total') {
            nameA = a.total
            nameB = b.total
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
    async exportReport () {
      if (this.detailArr.length > 0) {
        let utcSTART = moment(new Date(this.fromApiStartDate)).format("YYYY-MM-DD HH:mm:ss")
        let utcEND = moment(new Date(this.fromApiEndDate)).format("YYYY-MM-DD HH:mm:ss")
        let day1 = moment(this.fromApiStartDate).format("DD-MM-YY")
        let day2 = moment(this.fromApiEndDate).format("DD-MM-YY")
        let Time = ''
        let subURL = 'api/v1/analytics/amazon/sageRawData'
        let data = new FormData()
        if (this.activeIndex === 0) {
          Time = 'Sku-RawData--(' + this.selObj + ')-(' + day1 + '-To-' + day2 + ')'
          data.append('fromDate', utcSTART)
          data.append('toDate', utcEND)
          data.append('sku', this.selObj)
          data.append('sageItemCode', '')
          data.append('asin', '')
          data.append('orderIds', '')
          data.append('start', '')
          data.append('length', '')
          data.append('export', true)
        } else if (this.activeIndex === 1) {
          Time = 'ASIN-RawData--(' + this.selObj + ')-(' + day1 + '-To-' + day2 + ')'
          data.append('fromDate', utcSTART)
          data.append('toDate', utcEND)
          data.append('sku', '')
          data.append('sageItemCode', '')
          data.append('asin', this.selObj)
          data.append('orderIds', '')
          data.append('start', '')
          data.append('length', '')
          data.append('export', true)
        } else if (this.activeIndex === 2) {
          Time = 'SAGE Item Code-RawData--(' + this.selObj + ')-(' + day1 + '-To-' + day2 + ')'
          data.append('fromDate', utcSTART)
          data.append('toDate', utcEND)
          data.append('sku', '')
          data.append('sageItemCode', this.selObj)
          data.append('asin', '')
          data.append('orderIds', '')
          data.append('start', '')
          data.append('length', '')
          data.append('export', true)
        }
        await this.downloadAttachment(subURL, `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    clearSearch () {
      // this.selectAllCheck = false
      this.searchVal = ''
      this.getListingDetail()
    },
    amountFormaterWithToFix (amount) {
      let value = parseFloat(amount).toFixed(2)
      // return '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return `$${value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    },
  },
  destroyed() {
    localStorage.removeItem('cogsAmazonTitleObj')
  },
  beforeDestroy () {
    localStorage.removeItem('cogsAmazonTitleObj')
  }
}
</script>
<style scoped>

.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.xmsmall_col {
  min-width: 50px !important;
  width: 50px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell_col {
  min-width: 70px !important;
  width: 70px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.val1_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.val_col {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallC_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;

}
.small_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;

}


.asin_col {
  min-width: 183px !important;
  width: 183px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.mid_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.largeA_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.large_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallB_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallA_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.Xlarge_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.regular_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.desc_col {
  min-width: 390px !important;
  width: 390px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.lableWidth {
  width: 130px;
}
.dotwidth {
  width: 15px;
}
.amz_description_col {
  min-width: 320px !important;
  width: 320px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
