<template>
  <div>
    <div class="bg-white cust_card h-full p-1" v-if="firstLoading">
      <div class="">
        <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder  relative mt-2 bg-white">
          <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6 py-1 items-center" style="position: sticky;top: 0;background: white;z-index: 10;">
              <div class="small_col  h-16 flex items-center sticky left-0 bg-white">
                <span class="cursor-pointer" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="large_col  h-16 flex items-center" >
                <span class="cursor-pointer" @click="sortByColumn(1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="xmsmall_col  h-16 flex items-center text-right justify-end">
                <span class="cursor-pointer" @click="sortByColumn(2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="xmsmall_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(3, titleObj[3].sort, titleObj[3].value)">{{titleObj[3].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(4, titleObj[4].sort, titleObj[4].value)">{{titleObj[4].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(5, titleObj[5].sort, titleObj[5].value)">{{titleObj[5].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(6, titleObj[6].sort, titleObj[6].value)">{{titleObj[6].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[6].value) && titleObj[6].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[6].value) && !titleObj[6].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(7, titleObj[7].sort, titleObj[7].value)">{{titleObj[7].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[7].value) && titleObj[7].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[7].value) && !titleObj[7].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(20, titleObj[20].sort, titleObj[20].value)">{{titleObj[20].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[20].value) && titleObj[20].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[20].value) && !titleObj[20].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(8, titleObj[8].sort, titleObj[8].value)">{{titleObj[8].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[8].value) && titleObj[8].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[8].value) && !titleObj[8].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(9, titleObj[9].sort, titleObj[9].value)">{{titleObj[9].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[9].value) && titleObj[9].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[9].value) && !titleObj[9].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(10, titleObj[10].sort, titleObj[10].value)">{{titleObj[10].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[10].value) && titleObj[10].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[10].value) && !titleObj[10].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(11, titleObj[11].sort, titleObj[11].value)">{{titleObj[11].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[11].value) && titleObj[11].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[11].value) && !titleObj[11].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(12, titleObj[12].sort, titleObj[12].value)">{{titleObj[12].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[12].value) && titleObj[12].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[12].value) && !titleObj[12].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(13, titleObj[13].sort, titleObj[13].value)">{{titleObj[13].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[13].value) && titleObj[13].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[13].value) && !titleObj[13].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(14, titleObj[14].sort, titleObj[14].value)">{{titleObj[14].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[14].value) && titleObj[14].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[14].value) && !titleObj[14].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(15, titleObj[15].sort, titleObj[15].value)">{{titleObj[15].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[15].value) && titleObj[15].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[15].value) && !titleObj[15].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(16, titleObj[16].sort, titleObj[16].value)">{{titleObj[16].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[16].value) && titleObj[16].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[16].value) && !titleObj[16].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="total_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(17, titleObj[17].sort, titleObj[17].value)">{{titleObj[17].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[17].value) && titleObj[17].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[17].value) && !titleObj[17].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(18, titleObj[18].sort, titleObj[18].value)">{{titleObj[18].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[18].value) && titleObj[18].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[18].value) && !titleObj[18].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  h-16 flex items-center text-right justify-end" >
                <span class="cursor-pointer" @click="sortByColumn(19, titleObj[19].sort, titleObj[19].value)">{{titleObj[19].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[19].value) && titleObj[19].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[19].value) && !titleObj[19].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
            </div>
            <div :style="`max-height:` + cardHight + `px !important;min-height:` + cardHight + `px !important;`" class="" v-if="reportListing.length > 0 && firstLoading">
              <div class="min-w-min group flex border-r border-l border-b p-2 border-gray1 bg-white text-sm items-center py-1  hover:bg-blue-50" :class="{'bg-gray-50': indexA % 2}"
              v-for="(value, indexA) in reportListing" :key="indexA">
              <div class="small_col cursor-pointer text-primary sticky left-0 bg-white group-hover:bg-blue-50 flex items-center" style="height: 75px !important;" :class="{'bg-gray-50': indexA % 2}">
                <div class="w-16">
                    <img v-if="value.asinThumbnail !== ''" class="border border-gray-100 rounded-xl bg-white" style="height: 42px;width:80px;object-fit: contain;" :src="value.asinThumbnail">
                    <img v-else class="border border-gray-100 rounded-xl px-2 bg-white" style="height: 40px;object-fit: contain;" src="@/assets/default.png">
                  </div>
                  <span class="heading-6-1 text-text1 pl-2" v-if="value.asin !== ''">{{value.asin}}</span>
                  <span class="heading-6-1 text-text1 pl-2" v-else>--</span>
                </div>
                <div class="large_col ">
                  <span class="heading-6-1 text-text1">
                    <span class="mb-0 whitespace-pre-line">
                      {{value.amazonDesc === '' ? '--' : (value.amazonDesc.length > 133)? value.isDescViewM ?  value.amazonDesc : value.amazonDesc.substr(0, 133) : value.amazonDesc}}
                      <span v-if="value.amazonDesc.length > 133" class="text-primary cursor-pointer heading-7" @click="value.isDescViewM = !value.isDescViewM">
                        {{value.isDescViewM === false ? 'Read More...' : 'Read Less...'}}</span>
                    </span>
                  </span>
                  <!-- <span class="heading-6-1 text-text1" v-if="value.amazonDesc !== ''">{{value.amazonDesc}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span> -->
                </div>
                <div class="xmsmall_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvQuantity}}</span>
                </div>
                <div class="xmsmall_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.qtySold}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.avgSellingPrice | amountFormaterWithToFix}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.stdCOGS | amountFormaterWithToFix}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.avgMktFee | amountFormaterWithToFix}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.avgFbaFee | amountFormaterWithToFix}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.avgFbmFee | amountFormaterWithToFix}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{ value.newAddCost | amountFormaterWithToFix}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.avgInboundFee | amountFormaterWithToFix}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{ value.newAvgUnitNet | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.totalSales | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.totalCogs | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.totalFbaFee | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.totalMktFee | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.totalAdCost | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.totalInboundFees | amountFormaterWithToFix}}</span>
                </div>
                <div class="total_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.totalNet | amountFormaterWithToFix}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.avgCogs | amountFormaterWithToFix}}</span>
                </div>
                <div class="avgSell_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.lastCogs | amountFormaterWithToFix}}</span>
                </div>
              </div>
            </div>
          </div>
            <div class="layout m-2" v-if="reportListing.length === 0 && firstLoading">
              <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  <p class=" font-medium heading-4">No record found.</p>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
</template>
<script>
import deboucneMixin from '@/mixins/debounce.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  mixins: [deboucneMixin],
  components: {
  },
  data () {
    return {
      titleObj: [
        {title: 'ASIN', icon: '', value: 'asin', sort: true},
        {title: 'Amazon Desc.', icon: '', value: 'amazonDesc', sort: true},
        {title: 'FBA Qty.', icon: '', value: 'fbaInvQuantity', sort: true},
        {title: 'Qty. Sold', icon: '', value: 'qtySold', sort: true},
        {title: 'Avg. Selling Price', icon: '', value: 'avgSellingPrice', sort: true},
        {title: 'Std. COGS', icon: '', value: 'stdCOGS', sort: true},
        {title: 'Avg. Mkt Fee', icon: '', value: 'avgMktFee', sort: true},
        {title: 'Avg. FBA Fee', icon: '', value: 'avgFbaFee', sort: true},
        {title: 'Avg. Ad Cost', icon: '', value: 'newAddCost', sort: true},
        {title: 'Avg. Inbound Fees', icon: '', value: 'avgInboundFee', sort: true},
        {title: 'Avg. Unit Net', icon: '', value: 'newAvgUnitNet', sort: true},
        {title: 'Total Sales', icon: '', value: 'totalSales', sort: true},
        {title: 'Total COGS', icon: '', value: 'totalCogs', sort: true},
        {title: 'Total FBA Fee', icon: '', value: 'totalFbaFee', sort: true},
        {title: 'Total MKT Fee', icon: '', value: 'totalMktFee', sort: true},
        {title: 'Total Ad Cost', icon: '', value: 'totalAdCost', sort: true},
        {title: 'Total Inbound Fees', icon: '', value: 'totalInboundFees', sort: true},
        {title: 'Total Net', icon: '', value: 'totalNet', sort: true},
        {title: 'Avg. COGS', icon: '', value: 'avgCogs', sort: true},
        {title: 'Last COGS', icon: '', value: 'lastCogs', sort: true},
        {title: 'Avg. FBM Fee', icon: '', value: 'avgFbmFee', sort: true},
      ],
      pagination: {
        sortBy: 'asin',
        descending: true
      },
      cardHight: 0,
      mainHeight: 0,
    }
  },
  props: ["reportListing", "firstLoading"],
  created() {
  },
  mounted () {
    this.cardHight = window.innerHeight - 221
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    document.title = 'COGS ◾ Amazon Sage'
  },
  watch: {
  },
  methods: {
    sortByColumn (i, val1, val2) {
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      if (this.pagination.descending) {
        this.reportListing.sort(function (a, b) {
          if (val2 === 'amazonSKU') {
            var nameA = a.amazonSKU
            var nameB = b.amazonSKU
          } else if (val2 === 'asin') {
            nameA = a.asin
            nameB = b.asin
          } else if (val2 === 'amazonDesc') {
            nameA = a.amazonDesc
            nameB = b.amazonDesc
          } else if (val2 === 'sageItemCode') {
            nameA = a.sageItemCode
            nameB = b.sageItemCode
          } else if (val2 === 'sageItemDesc') {
            nameA = a.sageItemDesc
            nameB = b.sageItemDesc
          } else if (val2 === 'qtySold') {
            nameA = a.qtySold
            nameB = b.qtySold
          } else if (val2 === 'fbaInvQuantity') {
            nameA = a.fbaInvQuantity
            nameB = b.fbaInvQuantity
          } else if (val2 === 'avgSellingPrice') {
            nameA = a.avgSellingPrice
            nameB = b.avgSellingPrice
          } else if (val2 === 'stdCOGS') {
            nameA = a.stdCOGS
            nameB = b.stdCOGS
          } else if (val2 === 'avgMktFee') {
            nameA = a.avgMktFee
            nameB = b.avgMktFee
          } else if (val2 === 'avgFbaFee') {
            nameA = a.avgFbaFee
            nameB = b.avgFbaFee
          } else if (val2 === 'newAddCost') {
            nameA = a.newAddCost
            nameB = b.newAddCost
          } else if (val2 === 'avgInboundFee') {
            nameA = a.avgInboundFee
            nameB = b.avgInboundFee
          } else if (val2 === 'newAvgUnitNet') {
            nameA = a.newAvgUnitNet
            nameB = b.newAvgUnitNet
          } else if (val2 === 'totalSales') {
            nameA = a.totalSales
            nameB = b.totalSales
          } else if (val2 === 'totalCogs') {
            nameA = a.totalCogs
            nameB = b.totalCogs
          } else if (val2 === 'totalFbaFee') {
            nameA = a.totalFbaFee
            nameB = b.totalFbaFee
          } else if (val2 === 'totalMktFee') {
            nameA = a.totalMktFee
            nameB = b.totalMktFee
          } else if (val2 === 'totalAdCost') {
            nameA = a.totalAdCost
            nameB = b.totalAdCost
          } else if (val2 === 'totalInboundFees') {
            nameA = a.totalInboundFees
            nameB = b.totalInboundFees
          } else if (val2 === 'avgCogs') {
            nameA = a.avgCogs
            nameB = b.avgCogs
          } else if (val2 === 'lastCogs') {
            nameA = a.lastCogs
            nameB = b.lastCogs
          } else if (val2 === 'totalNet') {
            nameA = a.totalNet
            nameB = b.totalNet
          } else if (val2 === 'avgFbmFee') {
            nameA = a.avgFbmFee
            nameB = b.avgFbmFee
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        this.reportListing.reverse(function (a, b) {
          if (val2 === 'amazonSKU') {
            var nameA = a.amazonSKU
            var nameB = b.amazonSKU
          } else if (val2 === 'asin') {
            nameA = a.asin
            nameB = b.asin
          } else if (val2 === 'amazonDesc') {
            nameA = a.amazonDesc
            nameB = b.amazonDesc
          } else if (val2 === 'sageItemCode') {
            nameA = a.sageItemCode
            nameB = b.sageItemCode
          } else if (val2 === 'sageItemDesc') {
            nameA = a.sageItemDesc
            nameB = b.sageItemDesc
          } else if (val2 === 'qtySold') {
            nameA = a.qtySold
            nameB = b.qtySold
          } else if (val2 === 'fbaInvQuantity') {
            nameA = a.fbaInvQuantity
            nameB = b.fbaInvQuantity
          } else if (val2 === 'avgSellingPrice') {
            nameA = a.avgSellingPrice
            nameB = b.avgSellingPrice
          } else if (val2 === 'stdCOGS') {
            nameA = a.stdCOGS
            nameB = b.stdCOGS
          } else if (val2 === 'avgMktFee') {
            nameA = a.avgMktFee
            nameB = b.avgMktFee
          } else if (val2 === 'avgFbaFee') {
            nameA = a.avgFbaFee
            nameB = b.avgFbaFee
          } else if (val2 === 'newAddCost') {
            nameA = a.newAddCost
            nameB = b.newAddCost
          } else if (val2 === 'avgInboundFee') {
            nameA = a.avgInboundFee
            nameB = b.avgInboundFee
          } else if (val2 === 'newAvgUnitNet') {
            nameA = a.newAvgUnitNet
            nameB = b.newAvgUnitNet
          } else if (val2 === 'totalSales') {
            nameA = a.totalSales
            nameB = b.totalSales
          } else if (val2 === 'totalCogs') {
            nameA = a.totalCogs
            nameB = b.totalCogs
          } else if (val2 === 'totalFbaFee') {
            nameA = a.totalFbaFee
            nameB = b.totalFbaFee
          } else if (val2 === 'totalMktFee') {
            nameA = a.totalMktFee
            nameB = b.totalMktFee
          } else if (val2 === 'totalAdCost') {
            nameA = a.totalAdCost
            nameB = b.totalAdCost
          } else if (val2 === 'totalInboundFees') {
            nameA = a.totalInboundFees
            nameB = b.totalInboundFees
          } else if (val2 === 'avgCogs') {
            nameA = a.avgCogs
            nameB = b.avgCogs
          } else if (val2 === 'lastCogs') {
            nameA = a.lastCogs
            nameB = b.lastCogs
          } else if (val2 === 'totalNet') {
            nameA = a.totalNet
            nameB = b.totalNet
          } else if (val2 === 'avgFbmFee') {
            nameA = a.avgFbmFee
            nameB = b.avgFbmFee
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
  },
  destroyed() {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>

.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.xmsmall_col {
  min-width: 50px !important;
  width: 50px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.avgSell_col {
  min-width: 70px !important;
  width: 70px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.val1_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.val_col {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallC_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

}
.small_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.mid_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.largeA_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.large_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallB_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallA_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.Xlarge_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.regular_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.desc_col {
  min-width: 390px !important;
  width: 390px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.lableWidth {
  width: 130px;
}
.dotwidth {
  width: 15px;
}
</style>
