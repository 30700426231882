<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`"> 
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-9' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <div class="flex items-center">
              <span>Sales Reports (Sage, Amazon, Shipworks)</span>
              <div style="letter-spacing: 1px;" class="px-2 mx-2 mt-0.5 py-0.5  heading-7 rounded flex items-center border border-primary">
                <!-- <i class="fas fa-file-export h-5 w-5 text-white"></i> -->
                Filter By: {{ activeIndex === 0 ? 'SAGE Item Code' : activeIndex === 1 ? 'SKU' : activeIndex === 2 ? 'ASIN' : activeIndex === 3 ? 'Raw' : '' }}
              </div>
              <div v-if="fromApiStartDate !== '' && fromApiEndDate !== '' && firstLoading && !isSelectCustom && !isFilterOpen" class="flex items-center">
                <p class="text-text2 heading-5  pt-0.5 font-semibold">
                  <span class="text-text2 heading-5 font-semibold">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-5 font-semibold">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-primary mt-0.5">
                  {{this.selectedValueinText}}
                </div>
              </div>
              <div v-if="dateDiffCount !== '' && firstLoading && isSelectCustom && !isFilterOpen" class="flex items-center">
                <p class="text-text2 heading-5 pt-0.5 font-semibold">
                  <span class="text-text2 heading-5 font-semibold">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-5 font-semibold">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-primary mt-0.5">
                  {{this.dateDiffCount}} Days
                </div>
              </div>
            </div>
            <div class="flex items-center">
              <div class="">
                <button @click="openFbaInv2()"
                :class="`
                  ${isFilterLock ? 'mr-2' : 'mr-4'}
                  `"
                  style="letter-spacing: 1px;" class="p-1 px-2 cust_card heading-6 rounded flex items-center text-white bg-primary2 cursor-pointer">
                  ASIN Sold Count
                </button>
              </div>
              <div class="" v-if="activeIndex === 1">
                <button @click="openFbaInv()"
                :class="`
                  ${isFilterLock ? 'mr-2' : 'mr-4'}
                  `"
                  style="letter-spacing: 1px;" class="p-1 px-2 cust_card heading-6 rounded flex items-center text-white bg-primary2 cursor-pointer">
                  <!-- <i class="fas fa-file-export h-5 w-5 text-white"></i> -->
                  FBA Inventory
                </button>
              </div>
              <div class="">
                <button @click="report0Sales()" 
                :class="`
                  ${firstLoading && !isShowZeroSales ? 'text-white bg-primary2 cursor-pointer' : isShowZeroSales ? 'text-white bg-success cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'}
                  ${isFilterLock ? 'mr-2' : 'mr-4'}
                  `"
                  style="letter-spacing: 1px;" class="p-1 px-2 cust_card heading-6 rounded flex items-center">
                  <!-- <i class="fas fa-file-export h-5 w-5 text-white"></i> -->
                  Report for 0 Sales
                </button>
              </div>
              <div class="">
                <button @click="exportReport()" 
                :class="`
                  ${firstLoading && reportListing.length > 0 ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'}
                  ${isFilterLock ? 'mr-0' : 'mr-10'}
                  `"
                  style="letter-spacing: 1px;" class="p-1 px-2 cust_card heading-6 rounded flex items-center">
                  <!-- <i class="fas fa-file-export h-5 w-5 text-white"></i> -->
                  Export to Excel
                </button>
              </div>
            </div>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" v-if="!isFilterLock">
              <button id="toggle" class="bg-gray3 hover:bg-gray4 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div v-if="activeIndex === 0 ">
            <ItemCodeListing :isShowZeroSales="isShowZeroSales" :firstLoading="firstLoading" :reportListing="reportListing" :totalCountOfTotalNet="totalCountOfTotalNet" :totalCountOfQuntySold="totalCountOfQuntySold" :totalFBAQty="totalFBAQty" :adsCostOfTotalAdCostColumn="adsCostOfTotalAdCostColumn" :advertisingCostInPercentage="advertisingCostInPercentage" :totalSalesOfTotalSalesColumn="totalSalesOfTotalSalesColumn"  :startDate="filterObject.startDate" :endDate="filterObject.endDate" :isSelectCustom="isSelectCustom" :dateDiffCount="dateDiffCount" :selectedValueinText="selectedValueinText"></ItemCodeListing>
          </div>
          <div v-if="activeIndex === 1">
            <SKUListing :isShowZeroSales="isShowZeroSales" :firstLoading="firstLoading" :reportListing="reportListing" :totalCountOfTotalNet="totalCountOfTotalNet" :totalCountOfQuntySold="totalCountOfQuntySold" :totalFBAQty="totalFBAQty" :adsCostOfTotalAdCostColumn="adsCostOfTotalAdCostColumn" :advertisingCostInPercentage="advertisingCostInPercentage" :totalSalesOfTotalSalesColumn="totalSalesOfTotalSalesColumn"  :startDate="filterObject.startDate" :endDate="filterObject.endDate" :isSelectCustom="isSelectCustom" :dateDiffCount="dateDiffCount" :selectedValueinText="selectedValueinText"></SKUListing>
          </div>
          <div v-if="activeIndex === 2">
            <ASINListing :isShowZeroSales="isShowZeroSales" :firstLoading="firstLoading" :reportListing="reportListing" :totalCountOfTotalNet="totalCountOfTotalNet" :totalCountOfQuntySold="totalCountOfQuntySold" :totalFBAQty="totalFBAQty" :adsCostOfTotalAdCostColumn="adsCostOfTotalAdCostColumn" :advertisingCostInPercentage="advertisingCostInPercentage" :totalSalesOfTotalSalesColumn="totalSalesOfTotalSalesColumn"  :startDate="filterObject.startDate" :endDate="filterObject.endDate" :isSelectCustom="isSelectCustom" :dateDiffCount="dateDiffCount" :selectedValueinText="selectedValueinText"></ASINListing>
          </div>
          <div v-if="activeIndex === 3">
            <rowData :startDate="filterObject.startDate" :endDate="filterObject.endDate" :isSelectCustom="isSelectCustom" :dateDiffCount="dateDiffCount" :selectedValueinText="selectedValueinText"></rowData>
          </div>
        </div>
        <div class="box col-span-3 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" id="mainDiv" v-if="isFilterOpen">
          <div class="bg-white">
            <div class=" bg-gray3 p-2 items-center justify-between flex h-11 sticky top-0" :class="isFilterLock ? 'rounded-md' : ''" >
              <div class="flex items-center">
                <div class="pr-3 heading-5 text-white cursor-pointer" v-if="!isFilterLock" @click="lockBtn()"><i class="fa-solid fa-unlock"></i></div>
                <span class="pr-3 heading-5 text-white cursor-pointer" v-if="isFilterLock" @click="lockBtn()"><i class="fa-solid fa-lock"></i></span>
                <div class="heading-4 text-white font-bold">Filter</div>
              </div>
              <div class=" text-white cursor-pointer hover:underline" @click="applyFilter(false)">
                <button style="letter-spacing: 1px;" class="border border-primary bg-primary px-3 py-1 cust_card heading-7">Apply</button>
              </div>
            </div> 
            <div class="pt-1">
              <p class="text-error heading-6  pl-2 p-1 rounded font-semibold" v-if="showOtherWarning"  style="contain: inline-size;">Since date range selected is greater than 365 days, it may take some time to load the data.</p>
              <div v-if="filterObject.startDate !== '' || filterObject.endDate !== ''">
                <p class="text-text1 heading-6 font-bold pl-2">
                  <span class="text-text2 heading-6 font-bold">{{filterObject.startDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-6 font-bold">{{filterObject.endDate | DMMYYYYddd}}</span>
                </p>
              </div>
              <div class="pt-1 pl-1">
                <button style="letter-spacing: 1px"
                v-for="(data, index) in buttonFiltersArray" :key="index"
                  @click="getDateTimeForFilterHandler(index, true)"
                  :class="data.selected ? 'text-white bg-primary2' : 'text-primary2 bg-white hover:bg-blue-50'"
                  class="m-1 px-1 py-0.5 heading-7 rounded-md border border-primary2"
                  >{{data.title}}</button
                >
              </div>
            </div>
            <div class="divider m-1"></div>
            <div class="p-2 pt-1" >
              <div style="display:flex" class="search_customer_box text-text2 bg-white border border-gray-400 rounded-md px-2 py-1 items-center h-8 mt-1" >
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Search...." id="custSearchInput"
                  v-model="searchVal"
                >
                <span v-if="searchVal.length > 0" class="cursor-pointer"  @click="clearSearch()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <!-- <div class="flex group items-center sticky top-11 bg-white"> -->
              <div class="flex group items-center">
                <div class="mb-2">
                  <TabCompo @setNewTabActive="tabsSet" :tabsList="tabs" />
                </div>
              </div>
              <!-- <p class=" text-text2 heading-6">Total Selected {{filterObject.sendItemId.length === 0 ? 'Item' : 'Items'}}: {{filterObject.sendItemId.length}}</p> -->
              <div v-if="activeIndex === 0">
                <div class="pr-2 pt-1 pb-1 flex" v-if="!isShowData">
                  <button @click="showConditionWiseData('SAGE')" style="letter-spacing: 0.5px;" class="bg-primary text-white rounded-md px-2 py-1 heading-7 ">Show all Item Code</button>
                </div>
                <div v-if="isShowData">
                  <div class="flex justify-center mt-3" v-if="searchLoader">
                    <span class="loader2 flex ml-1"></span>
                  </div>
                  <div class="flex pt-0 px-2 pb-1 items-center justify-between" v-if="itemListing.length > 0">
                    <div class="flex items-center cursor-pointer" @click="selectAll()" >
                      <input type="checkbox" class="w-4 h-4" v-model="selectAllCheck">
                      <div class="text-text1 pl-1 heading-6">
                        {{!selectAllCheck ? 'Select All' : 'Deselect All'}}
                      </div>
                    </div>
                    <div class="text-text1 flex items-center">
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length > 0">({{dataBase.length}} Selected)</div>
                      <!-- <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length === 0 && selectAllCheck">({{itemListing.length}} Selected)</div> -->
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length === 0">(Total {{itemListing.length}} Sage Item Codes)</div>
                      <button  @click="resetAll()" style="letter-spacing: 1px;" class="border  cursor-pointer  border-gray-400 rounded-md px-2 py-0.5 heading-7">Clear</button>
                    </div>
                  </div>
                  <div class="overflow-auto" style="height: 560px !important;" v-if="itemListing.length > 0">
                    <div v-for="(data, index) in itemListing" :key="index">
                      <div class="flex items-start cust_card py-2 hover:bg-gray-50 px-2" v-if="data.iC !== ''">
                        <input type="checkbox" class="h-4 w-4 cursor-pointer mt-1" v-model="data.iSe" @click="selectData(data, index)">
                        <div class="pl-1 w-full cursor-pointer flex justify-between" @click="selectData(data, index)">
                          <p class=" text-text1 pl-1 heading-6 ">{{data.iC}}</p>
                          <!-- <p class=" text-primary2 pl-1 heading-6 " @click="openDetails(data)">{{data.productList.length}} Items</p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="layout" v-if="itemListing.length === 0 && isRefresh">
                    <div class="flex p-2 text-sm text-error border border-error rounded items-center" role="alert">
                      <div class="flex items-center">
                        <i class="fa-solid fa-circle-exclamation heading-4"></i>
                        <p class=" font-medium heading-5 pl-2">No record found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeIndex === 1">
                <div class="pr-2 pt-1 pb-1 flex" v-if="!isShowData">
                  <button @click="showConditionWiseData('SKU')" style="letter-spacing: 0.5px;" class="bg-primary text-white rounded-md px-2 py-1 heading-7 ">Show all SKU Data</button>
                </div>
                <div v-if="isShowData">
                  <!-- <div style="display:flex" class="search_customer_box text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-8 mt-1">
                    <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="SKU Search" id="custSearchInput"
                      v-model="searchVal"
                    >
                    <span v-if="searchVal.length > 0" class="cursor-pointer"  @click="clearSearch()">
                      <i class="fas fa-times h-4 w-4 text-gray4"></i>
                    </span>
                  </div> -->
                  <div class="flex justify-center mt-3" v-if="searchLoader">
                    <span class="loader2 flex ml-1"></span>
                  </div>
                  <div class="flex pt-0 px-2 pb-1 items-center justify-between" v-if="itemListing.length > 0">
                    <div class="flex items-center cursor-pointer" @click="selectAll()" >
                      <input type="checkbox" class="w-4 h-4" v-model="selectAllCheck">
                      <div class="text-text1 pl-1 heading-6">
                        {{!selectAllCheck ? 'Select All' : 'Deselect All'}}
                      </div>
                    </div>
                    <div class="text-text1 flex items-center">
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length > 0">({{dataBase.length}} Selected)</div>
                      <!-- <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length === 0 && selectAllCheck">({{itemListing.length}} Selected)</div> -->
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length === 0">(Total {{itemListing.length}} SKUs)</div>
                      <button  @click="resetAll()" style="letter-spacing: 1px;" class="border  cursor-pointer  border-gray-400 rounded-md px-2 py-0.5 heading-7">Clear</button>
                    </div>
                  </div>
                  <div class="overflow-auto" style="height: 560px !important;" v-if="itemListing.length > 0">
                    <div v-for="(data, index) in itemListing" :key="index">
                      <div class="flex items-start cust_card py-2 hover:bg-gray-50 px-2" v-if="data.iC !== ''">
                        <input type="checkbox" class="h-4 w-4 cursor-pointer mt-1" v-model="data.iSe" @click="selectData(data, index)">
                        <div class="pl-1 w-full cursor-pointer flex justify-between" @click="selectData(data, index)">
                          <p class=" text-text1 pl-1 heading-6 ">{{data.iC}}</p>
                          <!-- <p class=" text-primary2 pl-1 heading-6 " @click="openDetails(data)">{{data.productList.length}} Items</p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="layout" v-if="itemListing.length === 0 && isRefresh">
                    <div class="flex p-2 text-sm text-error border border-error rounded items-center" role="alert">
                      <div class="flex items-center">
                        <i class="fa-solid fa-circle-exclamation heading-4"></i>
                        <p class=" font-medium heading-5 pl-2">No record found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeIndex === 2">
                <div class="pr-2 pt-1 pb-1 flex" v-if="!isShowData">
                  <button @click="showConditionWiseData('ASIN')" style="letter-spacing: 0.5px;" class="bg-primary text-white rounded-md px-2 py-1 heading-7 ">Show all ASIN Data</button>
                </div>
                <div v-if="isShowData">
                  <!-- <div style="display:flex" class="search_customer_box text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-8 mt-1">
                    <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="ASIN Search" id="custSearchInput"
                      v-model="searchVal"
                    >
                    <span v-if="searchVal.length > 0" class="cursor-pointer"  @click="clearSearch()">
                      <i class="fas fa-times h-4 w-4 text-gray4"></i>
                    </span>
                  </div> -->
                  <div class="flex justify-center mt-3" v-if="searchLoader">
                    <span class="loader2 flex ml-1"></span>
                  </div>
                  <div class="flex pt-0 px-2 pb-1 items-center justify-between" v-if="itemListing.length > 0">
                    <div class="flex items-center cursor-pointer" @click="selectAll()" >
                      <input type="checkbox" class="w-4 h-4" v-model="selectAllCheck">
                      <div class="text-text1 pl-1 heading-6">
                        {{!selectAllCheck ? 'Select All' : 'Deselect All'}}
                      </div>
                    </div>
                    <div class="text-text1 flex items-center">
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length > 0">({{dataBase.length}} Selected)</div>
                      <!-- <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length === 0 && selectAllCheck">({{itemListing.length}} Selected)</div> -->
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length === 0">(Total {{itemListing.length}} ASINs)</div>
                      <button  @click="resetAll()" style="letter-spacing: 1px;" class="border  cursor-pointer  border-gray-400 rounded-md px-2 py-0.5 heading-7">Clear</button>
                    </div>
                  </div>
                  <div class="overflow-auto" style="height: 560px !important;" v-if="itemListing.length > 0">
                    <div v-for="(data, index) in itemListing" :key="index">
                      <div class="flex items-start cust_card py-2 hover:bg-gray-50 px-2" v-if="data.iC !== ''">
                        <input type="checkbox" class="h-4 w-4 cursor-pointer mt-1" v-model="data.iSe" @click="selectData(data, index)">
                        <div class="pl-1 w-full cursor-pointer flex justify-between" @click="selectData(data, index)">
                          <p class=" text-text1 pl-1 heading-6 ">{{data.iC}}</p>
                          <!-- <p class=" text-primary2 pl-1 heading-6 " @click="openDetails(data)">{{data.productList.length}} Items</p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="layout" v-if="itemListing.length === 0 && isRefresh">
                    <div class="flex p-2 text-sm text-error border border-error rounded items-center" role="alert">
                      <div class="flex items-center">
                        <i class="fa-solid fa-circle-exclamation heading-4"></i>
                        <p class=" font-medium heading-5 pl-2">No record found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeIndex === 3">
                <div class="pr-2 pt-1 pb-1 flex" v-if="!isShowData">
                  <button @click="showConditionWiseData('RAW')" style="letter-spacing: 0.5px;" class="bg-primary text-white rounded-md px-2 py-1 heading-7 ">Show all Order Id</button>
                </div>
                <div v-if="isShowData">
                  <!-- <div style="display:flex" class="search_customer_box text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-8 mt-1">
                    <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Order Id Search" id="custSearchInput"
                      v-model="searchVal"
                    >
                    <span v-if="searchVal.length > 0" class="cursor-pointer"  @click="clearSearch()">
                      <i class="fas fa-times h-4 w-4 text-gray4"></i>
                    </span>
                  </div> -->
                  <div class="flex justify-center mt-3" v-if="searchLoader">
                    <span class="loader2 flex ml-1"></span>
                  </div>
                  <div class="flex pt-0 px-2 pb-1 items-center justify-between" v-if="itemListing.length > 0">
                    <div class="flex items-center cursor-pointer" @click="selectAll()" >
                      <input type="checkbox" class="w-4 h-4" v-model="selectAllCheck">
                      <div class="text-text1 pl-1 heading-6">
                        {{!selectAllCheck ? 'Select All' : 'Deselect All'}}
                      </div>
                    </div>
                    <div class="text-text1 flex items-center">
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length > 0">({{dataBase.length}} Selected)</div>
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length === 0">(Total {{itemListing.length}} Order Ids)</div>
                      <button  @click="resetAll()" style="letter-spacing: 1px;" class="border  cursor-pointer  border-gray-400 rounded-md px-2 py-0.5 heading-7">Clear</button>
                    </div>
                  </div>
                  <div class="overflow-auto" style="height: 560px !important;" v-if="itemListing.length > 0">
                    <div v-for="(data, index) in itemListing" :key="index">
                      <div class="flex items-start cust_card py-2 hover:bg-gray-50 px-2" v-if="data.iC !== ''">
                        <input type="checkbox" class="h-4 w-4 cursor-pointer mt-1" v-model="data.iSe" @click="selectData(data, index)">
                        <div class="pl-1 w-full cursor-pointer flex justify-between" @click="selectData(data, index)">
                          <p class=" text-text1 pl-1 heading-6 ">{{data.iC}}</p>
                          <!-- <p class=" text-primary2 pl-1 heading-6 " @click="openDetails(data)">{{data.productList.length}} Items</p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="layout" v-if="itemListing.length === 0 && isRefresh">
                    <div class="flex p-2 text-sm text-error border border-error rounded items-center" role="alert">
                      <div class="flex items-center">
                        <i class="fa-solid fa-circle-exclamation heading-4"></i>
                        <p class=" font-medium heading-5 pl-2">No order id record found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay">
        <CustomDateSelector :showWarning="true" :appliedFilters="filterObject" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker"/>
      </div>
    </div>
    <div class="cust_card pb-2 popup_overlay px-4" v-if="openDetail">
    <div class="custom_dialog" style="width:1050px">
      <div class="rounded px-5 py-3 h-full bg-primary2 text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">{{selectedArr.iC}}</p>
        </div>
        <div class="flex gap-2">
          <div class=" text-white cursor-pointer hover:underline" @click="openDetail = false">
            <button style="letter-spacing: 1px;" class="bg-white text-primary2 px-3 py-1 cust_card heading-6 ">Close</button>
          </div>
        </div>  
      </div>
      <div class="" style="max-height: 690px !important;">
        <div class="" >
          <div class="cust_card box relative" style="overflow-y: auto;">
            <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6 py-1 items-center " style="position: sticky;top: 0;background: white;z-index: 10;">
              <div class="smallB_col flex items-center">
                <span class="">Sku</span>
              </div>
              <div class="smallB_col  flex items-center">
                <span class="">Amazon Details Sku</span>
              </div>
              <div class="smallC_col  flex items-center">
                <span class="">Asin</span>
              </div>
              <div class="smallB_col  flex items-center">
                <span class="">Sage Details Sku</span>
              </div>
              <div class="smallB_col  flex items-center">
                <span class="">Item Description</span>
              </div>
              <div class="largeA_col  flex items-center">
                <span class="">Description</span>
              </div>
            </div>
            <div class="" style="max-height: 610px;">
              <div class="min-w-min flex border-r border-l border-b p-2 border-gray1 bg-white text-sm items-center py-1 cursor-pointer hover:bg-gray-50" v-for="(value, index) in selectedArr.Obj" :key="index">
                <div class="smallB_col">
                  <span class="heading-6 text-text1">{{value.sku !== '' ? value.sku : '--'}}</span>
                </div>
                <div class="smallB_col">
                  <span class="heading-6 text-text1">{{value.amazon_details_sku !== '' ? value.amazon_details_sku : '--'}}</span>
                </div>
                <div class="smallC_col">
                  <span class="heading-6 text-text1">{{value.asin !== '' ? value.asin : '--'}}</span>
                </div>
                <div class="smallB_col">
                  <span class="heading-6 text-text1">{{value.sage_details_sku !== '' ? value.sage_details_sku : '--'}}</span>
                </div>
                <div class="smallB_col">
                  <span class="heading-6 text-text1">{{value.item_description !== '' ? value.item_description : '--'}}</span>
                </div>
                <div class="largeA_col">
                  <span class="heading-6 text-text1">{{value.description !== '' ? value.description : '--'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="openZeroDetail && activeIndex === 0">
    <zeroItemCodeListing :startDate="filterObject.startDate" :endDate="filterObject.endDate" :isSelectCustom="isSelectCustom" :dateDiffCount="dateDiffCount" :selectedValueinText="selectedValueinText"></zeroItemCodeListing>
  </div>
  <div v-if="openZeroDetail && activeIndex === 1">
    <zeroSkuListing :startDate="filterObject.startDate" :endDate="filterObject.endDate" :isSelectCustom="isSelectCustom" :dateDiffCount="dateDiffCount" :selectedValueinText="selectedValueinText"></zeroSkuListing>
  </div>
  <div v-if="openZeroDetail && activeIndex === 2">
    <zeroAsinListing :startDate="filterObject.startDate" :endDate="filterObject.endDate" :isSelectCustom="isSelectCustom" :dateDiffCount="dateDiffCount" :selectedValueinText="selectedValueinText"></zeroAsinListing>
  </div>
  <div v-if="openZeroDetail && activeIndex === 3">
    <zeroRowData :startDate="filterObject.startDate" :endDate="filterObject.endDate" :isSelectCustom="isSelectCustom" :dateDiffCount="dateDiffCount" :selectedValueinText="selectedValueinText"></zeroRowData>
  </div>
  <div v-if="showFBAInventory">
    <FbaInventory></FbaInventory>
  </div>
  <!-- <div>
    <zeroRowData></zeroRowData>
  </div> -->
  </div>
</template>
<script>
import TabCompo from '@/View/components/tabs.vue'
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
import CustomDateSelector from '@/View/components/customDateSelection'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
import deboucneMixin from '@/mixins/debounce.js'
import ItemCodeListing from '@/View/Admin/costOfGood/AmazonSage/itemCodeListing.vue'
import SKUListing from '@/View/Admin/costOfGood/AmazonSage/SkuListing.vue'
import ASINListing from '@/View/Admin/costOfGood/AmazonSage/AsinListing.vue'
import rowData from '@/View/Admin/costOfGood/AmazonSage/rowData.vue'
// import zeroAsinListing from './Component/zeroAsinListing.vue'
import zeroAsinListing from '@/View/Admin/costOfGood/AmazonSage/Component/asinZeroSales/index.vue'
import zeroSkuListing from '@/View/Admin/costOfGood/AmazonSage/Component/skuZeroSales/index.vue'
import zeroItemCodeListing from '@/View/Admin/costOfGood/AmazonSage/Component/SageItemCodeZeroSales/index.vue'
import zeroRowData from '@/View/Admin/costOfGood/AmazonSage/Component/rawZeroSales/index.vue'
// import zeroItemCodeListing from './Component/ZeroitemCodeListing.vue'
// import zeroRowData from './Component/ZerorowData.vue'
// import zeroSkuListing from './Component/zeroSkuListing.vue'
import FbaInventory from './Component/FbaInventory.vue'
// import Pagination from '@/View/components/pagination.vue'
export default {
  mixins: [deboucneMixin],
  components: {
    zeroAsinListing,
    zeroItemCodeListing,
    zeroRowData,
    FbaInventory,
    zeroSkuListing,
    TabCompo,
    rowData,
    CustomDateSelector,
    ItemCodeListing,
    SKUListing,
    ASINListing,
  },
  data () {
    return {
      showFBAInventory: false,
      openZeroDetail: false,
      isShowZeroSales: false,
      isShowData: false,
      isFilterLock: true,
      showAllSkudata: false,
      showAllASINdata: false,
      showAllSAGEdata: false,
      showAllRawdata: false,
      fromApiStartDate: '',
      fromApiEndDate: '',
      tabs: [
        {name: 'SAGE Item Code', active: true},
        {name: 'SKU', active: false},
        {name: 'ASIN', active: false},
        {name: 'Raw', active: false},
      ],
      isShowAllFilter: false,
      showOtherWarning: false,
      dateDiffCount: '',
      isFilterOpen: true,
      isRefresh: true,
      selectedArr: [],
      openDetail: false,
      searchVal: '',
      searchLoader: false,
      reportListing: [],
      selectAllCheck: true,
      itemListing: [],
      requiredDataListing: [],
      isSelectCustom: false,
      selectedValue: 'last30Days',
      selectedValueinText: 'Last 30 Days',
      titleObj: [
        {title: 'Amazon SKU', icon: '', value: 'amazonSKU', sort: true},
        {title: 'ASIN', icon: '', value: 'asin', sort: true},
        {title: 'Amazon Desc.', icon: '', value: 'amazonDesc', sort: true},
        {title: 'SAGE Item Code', icon: '', value: 'sageItemCode', sort: true},
        {title: 'SAGE Item Desc.', icon: '', value: 'sageItemDesc', sort: true},
        {title: 'Qty. Sold', icon: '', value: 'qtySold', sort: true},
        {title: 'Avg. Selling Price', icon: '', value: 'avgSellingPrice', sort: true},
        {title: 'Std. COGS', icon: '', value: 'stdCOGS', sort: true},
        {title: 'Avg. Mkt Fee', icon: '', value: 'avgMktFee', sort: true},
        {title: 'Avg. FBA Fee', icon: '', value: 'avgFbaFee', sort: true},
        {title: 'Avg. Ad Cost', icon: '', value: 'avgAdCost', sort: true},
        {title: 'Avg. Inbound Fees', icon: '', value: 'avgInboundFee', sort: true},
        {title: 'Avg. Unit Net', icon: '', value: 'avgUnitNet', sort: true},
        {title: 'Total Sales', icon: '', value: 'totalSales', sort: true},
        {title: 'Total COGS', icon: '', value: 'totalCogs', sort: true},
        {title: 'Total FBA Fee', icon: '', value: 'totalFbaFee', sort: true},
        {title: 'Total MKT Fee', icon: '', value: 'totalMktFee', sort: true},
        {title: 'Total Ad Cost', icon: '', value: 'totalAdCost', sort: true},
        {title: 'Total Inbound Fees', icon: '', value: 'totalInboundFees', sort: true},
        {title: 'Total Net', icon: '', value: 'totalNet', sort: true},
        {title: 'Avg. COGS', icon: '', value: 'avgCogs', sort: true},
        {title: 'Last COGS', icon: '', value: 'lastCogs', sort: true},
        {title: 'FBA Qty.', icon: '', value: 'fbaInvQuantity', sort: true},
      ],
      pagination: {
        sortBy: 'amazonSKU',
        descending: true
      },
      buttonFiltersArray: [
        // {
        //   title: 'Today',
        //   selected: false,
        //   value: 'today'
        // },
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        // {
        //   title: 'Last Week',
        //   selected: false,
        //   value: 'lastWeek'
        // },
        {
          title: 'Last 7 Days',
          selected: false,
          value: 'last7Days'
        },
        {
          title: 'This Month',
          selected: false,
          value: 'thisMonth'
        },
        {
          title: 'Last Month',
          selected: false,
          value: 'lastMonth'
        },
        {
          title: 'Last 30 Days',
          selected: true,
          value: 'last30Days'
        },
        {
          title: 'Last 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Last 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Last 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Last 180 Days',
          selected: false,
          value: 'last180Days'
        },
        {
          title: 'Last 365 Days',
          selected: false,
          value: 'lastYear'
        },
        {
          title: 'Month to Date',
          selected: false,
          value: 'thisMonth'
        },
        {
          title: 'Year to Date',
          selected: false,
          value: 'thisYear'
        },
        {
          title: 'Custom',
          selected: false,
          value: 'custom'
        }
      ],
      drag: false,
      tempArrA: [],
      filterObject: {
        startDate: '',
        endDate: '',
        sendItemId: [],
        minDate: '',
        maxDate: '',
      },
      dataBase: [],
      cardHight: 0,
      activeIndex: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
      getListingDetail: () => {},
      totalCountOfQuntySold: 0,
      totalFBAQty: 0,
      totalCountOfTotalNet: 0,
      adsCostOfTotalAdCostColumn: 0,
      advertisingCostInPercentage: 0,
      totalSalesOfTotalSalesColumn: 0,
      isQueryAvailable: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.$root.$on('closeZeroPopup', () => {
      document.body.style = 'overflow: visible;'
      this.openZeroDetail = false
    })
    this.$root.$on('closeFBAInPopup', () => {
      document.body.style = 'overflow: visible;'
      this.showFBAInventory = false
    })
    if (this.$route.query.searchValue !== undefined) {
      this.isQueryAvailable = true
      console.log('click this.$route.query', this.$route.query)
      this.activeIndex = parseInt(this.$route.query.tab)
      this.tabs.forEach((element, i) => {
        if (parseInt(this.$route.query.tab) === i) {
            element.active = true
            this.activeIndex = i
          } else {
            element.active = false
          }
      });
      this.searchVal = this.$route.query.searchValue
      this.apiCaller()
      this.filterObject.startDate = this.$route.query.from
      this.filterObject.endDate = this.$route.query.to
      this.selectedValueinText = this.$route.query.selectedValueinText
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (this.$route.query.isSelectCustom === false || this.$route.query.isSelectCustom === 'false') {
          this.dateDiffCount = ''
          this.isSelectCustom = false
          if (this.selectedValueinText === this.buttonFiltersArray[i].title) {
              console.log('selectedValueinText', this.selectedValueinText)
              this.selectedValue = this.buttonFiltersArray[i].value
              this.buttonFiltersArray[i].selected = true
            } else {
              this.buttonFiltersArray[i].selected = false
            }
          } else {
            this.isSelectCustom = true
            this.dateDiffCount = this.$route.query.dateDiffCount
            if ('Custom' === this.buttonFiltersArray[i].title) {
              this.selectedValue = this.buttonFiltersArray[i].value
              this.buttonFiltersArray[i].selected = true
            } else {
              this.buttonFiltersArray[i].selected = false
            }
          }
        }
      setTimeout(() => {
        setTimeout(() => {
          this.isShowData = true
        }, 300);
      }, 200);
    } else {
      this.isQueryAvailable = false
      this.defaultEndDate = new Date()
      let currentDate = new Date()
      this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
      this.filterObject.startDate = this.defaultStartDate
      this.filterObject.endDate = this.defaultEndDate
      this.getData()
      console.log('this.cardHight', this.cardHight, this.mainHeight)
      this.apiCaller()
    }
    document.title = 'COGS ◾ Amazon Sage'
    this.cardHight = window.innerHeight - 231
    this.mainHeight = window.innerHeight - 90
    // this.$root.$on('setNewTabActive', (index) => {
    //   console.log('index',index)
    //   this.tabsSet(index)
    // })
    this.$root.$on('rowDataObject', (Object) => {
      console.log('Object',Object)
      this.firstLoading = true
      let obj = {
        fromApiStartDate: Object.startTime,
        fromApiEndDate: Object.endTime
      }
      this.reportListing.push(obj)
      if (this.isSelectCustom === false) {
        for (let i = 0; i < this.buttonFiltersArray.length; i++) {
          if (this.selectedValue === this.buttonFiltersArray[i].value) {
            this.selectedValueinText = this.buttonFiltersArray[i].title
          }
        }
        this.fromApiStartDate = Object.startTime
        this.fromApiEndDate = Object.endTime
      } else {
        this.fromApiStartDate = Object.startTime
        this.fromApiEndDate = Object.endTime
        this.dateDiffCount = Object.diffrenceDate
      }
    })
    this.getListingDetail = this.debounce(function () {
      this.selectAllCheck = false
      if (this.searchVal !== '') {
        this.isShowData = true
      }
      this.getData()
    }, 500)
    
  },
  watch: {
    titleObj: {
      handler () {
        console.log('resetColumnLists WATCH_ titleObj___________________')
      },
      deep: true
    },
    dataBase: {
      handler () {
        console.log('Total Selected Items', this.dataBase.length)
        this.filterObject.sendItemId = []
        this.dataBase.forEach(a => {
          if (a.iC !== '') {
            this.filterObject.sendItemId.push(a)
          }
        })
        // this.getListing()
      },
      deep: true
    },
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
        const date1 = new Date(this.filterObject.startDate);
        const date2 = new Date(this.filterObject.endDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        console.log('diffDays ------->1', diffDays)
        if (diffDays > 365) {
          this.showOtherWarning = true
        } else {
          this.showOtherWarning = false
        }
      },
      deep: true
    },
    selectAllCheck: {
      handler () {
        console.log('selectAllCheck', this.selectAllCheck)
      },
      deep: true
    },
    searchVal: {
      handler() {
        this.getListingDetail()
      }
    },
  },
  methods: {
    lockBtn () {
      console.log('lock btn')
      this.isFilterLock = !this.isFilterLock
    },
    showConditionWiseData () {
      this.isShowData = true
      // if (value === 'SKU') {
      //   this.showAllSkudata = true
      // } else if (value === 'ASIN') {
      //   this.showAllASINdata = true
      // } else if (value === 'SAGE') {
      //   this.showAllSAGEdata = true
      // } else if (value === 'RAW') {
      //   this.showAllRawdata = true
      // }
    },
    tabsSet (index) {
      this.tabs.forEach((element, i) => {
          if (index === i) {
              element.active = true
              this.activeIndex = i
          } else {
              element.active = false
          }
      });
      this.isShowZeroSales = false
      this.isQueryAvailable = false
      // this.searchVal = ''
      this.selectAllCheck = true
      this.dataBase = []
      this.filterObject.sendItemId = []
      this.reportListing = []
      this.firstLoading = false
      this.fromApiStartDate = ''
      this.fromApiEndDate = ''
      this.dateDiffCount = ''
      if (this.searchVal !== '') {
        this.isShowData = true
      } else {
        this.isShowData = false
      }
      this.getData()
    },
    selectAll () {
      if (this.selectAllCheck === true) {
        this.selectAllCheck = false
      } else {
        this.selectAllCheck = true
      }
      if (this.searchVal === '') {
        if (this.selectAllCheck) {
          console.log('stage 1')
          this.dataBase = []
          for (let index = 0; index < this.itemListing.length; index++) {
            this.itemListing[index].iSe = true
          }
        } else if (this.selectAllCheck === false) {
          console.log('stage 2')
          this.dataBase = []
          for (let index = 0; index < this.itemListing.length; index++) {
            this.itemListing[index].iSe = false
            this.dataBase = []
          }
        }
      } else if (this.searchVal !== '') {
        if (this.selectAllCheck) {
          console.log('stage 3')
          // this.dataBase = []
          for (let index = 0; index < this.itemListing.length; index++) {
            if (this.itemListing[index].iSe === false) {
              this.itemListing[index].iSe = true
              this.dataBase.push(this.itemListing[index])
            }
          }
        } else if (this.selectAllCheck === false) {
          console.log('stage 4')
          this.dataBase = []
          let count = 0
          let tempArr = []
          for (let index = 0; index < this.itemListing.length; index++) {
            if (this.itemListing[index].iSe === true) {
              count ++
              tempArr.push(this.itemListing[index])
            }
          }
          console.log('count', count, tempArr)
          console.log('this.itemListing', this.itemListing)
          console.log('this.itemListing', this.itemListing)
          const intersection = this.itemListing.filter(element => tempArr.includes(element))
          const intersection2 = intersection.filter(element => !this.itemListing.includes(element))
          console.log('intersection1 of tw o array', intersection)
          console.log('intersection2 of tw o array', intersection2)
          this.dataBase = intersection2
          this.itemListing.forEach(element1 => {
            intersection2.forEach(element2 => {
              if (element1 === element2) {
                element1.iSe = true
              }
            })
          })
          for (let J = 0; J < this.itemListing.length; J++) {
             this.itemListing[J].iSe = false
          }
        }
      }
    },
    openCloseFilter () {
      this.isFilterOpen = !this.isFilterOpen
      console.log('openCloseFilter')
    },
    customDateListener (data) {
      console.log('this.selectedValueinText ---->', this.selectedValueinText);
      console.log('data ---->', data);
      this.showCustomDatepicker = false
      if (data !== null) {
        if (data.startDate === null) {
          this.filterObject.startDate = this.defaultStartDate
        } else {
          this.filterObject.startDate = new Date(new Date(data.startDate).setHours(0, 0, 0, 0))
        }
        if (data.endDate === null) {
          this.filterObject.endDate = this.defaultEndDate
        } else {
          this.filterObject.endDate = new Date(new Date(data.endDate).setHours(23, 59, 58, 998))
        }
      } else {
        // if (this.selectedValueinText === '')
        if (!this.isSelectCustom) {
          this.isSelectCustom = false
        }
      }
    },
    selectData (data, index) {
      console.log('00000000000 data.iSe', data)
      if (!this.selectAllCheck) {
        if (!data.iSe) {
          console.log('stage 10')
          this.dataBase.push(data)
          data.iSe = true
        } else {
          console.log('stage 11')
          const findex = this.dataBase.findIndex(x => x.iC === data.iC)
          this.itemListing[index].iSe = false
          this.dataBase.splice(findex, 1)
        }
      } else if (this.searchVal !== '' && this.selectAllCheck) {
        console.log('00000000000 this.selectAllCheck', this.selectAllCheck)
        console.log('00000000000 data.iSe', data)
        if (this.selectAllCheck && data.iSe) {
          this.selectAllCheck = false
          console.log('stage 7')
          this.dataBase = []
          for (let Ind = 0; Ind < this.itemListing.length; Ind++) {
            if (this.itemListing[Ind].iC === data.iC) {
              this.itemListing[Ind].iSe = false
            }
          }
          for (let J = 0; J < this.itemListing.length; J++) {
            if (this.itemListing[J].iSe) {
              this.dataBase.push(this.itemListing[J])
            }
          }
        } else {
          console.log('stage 8', this.itemListing)
          data.iSe = true
          const tempObj = this.itemListing.filter(element => !this.dataBase.includes(element))
          for (let I = 0; I < tempObj.length; I++) {
            if (tempObj[I].iSe === true) {
              this.dataBase.push(tempObj[I])
            }
            this.selectAllCheck = true   
          }
        }
      } else if (this.searchVal === '' && this.selectAllCheck && data.iSe) {
        console.log('stage 13')
        this.dataBase = []
        for (let Ind = 0; Ind < this.itemListing.length; Ind++) {
          if (this.itemListing[Ind].iC === data.iC) {
            this.itemListing[Ind].iSe = false
          }
        }
        for (let J = 0; J < this.itemListing.length; J++) {
          if (this.itemListing[J].iSe) {
            this.dataBase.push(this.itemListing[J])
          }
        }
        this.selectAllCheck = false
        data.iSe = false
      } else if (this.searchVal === '' && this.selectAllCheck && !data.iSe) {
        console.log('stage 14')
        data.iSe = true
        this.dataBase.push(data)
      }
      console.log('this.itemListing.length', this.itemListing.length)
      console.log('this.itemListing.length', this.dataBase.length)
      if (this.itemListing.length === this.dataBase.length) {
        this.selectAllCheck = true
      }
    },
    getDateTimeForFilterHandler (index, showCustomDatepicker) {
      this.selectedValue = this.buttonFiltersArray[index].value
      this.isSelectCustom = false
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (i !== index) {
          this.buttonFiltersArray[i].selected = false
        } else {
          this.buttonFiltersArray[i].selected = true
        }
      }
      if (this.buttonFiltersArray[index].value !== 'today' && this.buttonFiltersArray[index].value !== 'custom') {
        getDayAccordingToLimits(this.buttonFiltersArray[index].value).then(val => {
          this.filterObject.startDate = val.startDate
          this.filterObject.endDate = val.endDate
          // this.getListing()
        })
      } else {
        if (this.buttonFiltersArray[index].value === 'today') {
          this.filterObject.startDate = new Date(new Date().setHours(0, 0, 0, 0)).toUTCString()
          this.filterObject.endDate = new Date(new Date().setHours(23, 59, 0, 0)).toUTCString()
          // this.getListing()
        } else {
          this.showCustomDatepicker = showCustomDatepicker
          this.isSelectCustom = true
        }
      }
    },
    applyFilter(value) {
      if (!this.isFilterLock) {
        this.isFilterOpen = false
      }
      if (this.activeIndex === 0) {
        this.filterObject.sendItemId = []
        for (let II = 0; II < this.dataBase.length; II++) {
          for (let JJ = 0; JJ < this.dataBase[II].productList.length; JJ++) {
            if (this.dataBase[II].productList[JJ].product_id !== 0) {
              this.filterObject.sendItemId.push(this.dataBase[II].productList[JJ].product_id)
            }
          }
        } 
      } else if (this.activeIndex === 1) { 
        this.filterObject.sendItemId = []
        this.dataBase.forEach(a => {
          if (a.productId !== 0) {
            this.filterObject.sendItemId.push(a.productId)
          }
        })
      } else if (this.activeIndex === 2) {
        this.filterObject.sendItemId = []
        for (let II = 0; II < this.dataBase.length; II++) {
          for (let JJ = 0; JJ < this.dataBase[II].productList.length; JJ++) {
            if (this.dataBase[II].productList[JJ].productId !== 0) {
              this.filterObject.sendItemId.push(this.dataBase[II].productList[JJ].productId)
            }
          }
        } 
      } else if (this.activeIndex === 3) {
        this.filterObject.sendItemId = []
        this.dataBase.forEach(a => {
          if (a.iC !== '') {
            let orderId = `'` + a.iC + `'`
            this.filterObject.sendItemId.push(orderId)
          }
        })
        setTimeout(() => {
          this.$root.$emit('callRowData', this.filterObject)
        }, 200);
      }
      this.getListing(value)
    },
    resetAll () {
      this.filterObject.sendItemId = []
      // CHANGES
      // this.searchVal = ''
      this.dataBase = []
      this.selectAllCheck = false
      // this.filterObject.startDate = this.defaultStartDate
      // this.filterObject.endDate = this.defaultEndDate
      // for (let i = 0; i < this.buttonFiltersArray.length; i++) {
      //   if (this.buttonFiltersArray[i].value === 'last30Days') {
      //     this.buttonFiltersArray[i].selected = true
      //   } else {
      //     this.buttonFiltersArray[i].selected = false
      //   }
      // }
      for (let index = 0; index < this.itemListing.length; index++) {
        this.itemListing[index].iSe = false
      }
      // this.getListing()
    },
    apiCaller () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      API.GetAmazonSageListing(
        response => {
          this.requiredDataListing = response.Data === null ? [] : response.Data
          this.filterObject.minDate = this.requiredDataListing.minDate
          this.filterObject.maxDate = this.requiredDataListing.maxDate
          // this.filterObject.startDate = this.requiredDataListing.minDate
          // this.filterObject.endDate = this.requiredDataListing.maxDate
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getData () {
      this.itemListing = []
      this.searchLoader = true
      this.isRefresh = false
      if (this.activeIndex === 0) {
        API.GetSageItemCodeListing(
          this.searchVal,
          response => {
            this.isRefresh = true
            this.itemListing = response.Data === null ? [] : response.Data
            console.log('this.dataBase.length', this.dataBase)
            if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
              for (let I = 0; I < this.itemListing.length; I++) {
                const findex = this.dataBase.findIndex(x => x.iC === this.itemListing[I].iC)
                console.log('findex', findex)
                if (findex > -1) {
                  this.itemListing[I].iSe = true
                }
              }
            } else if (this.searchVal === '' && this.dataBase.length === 0) {
              this.selectAllCheck = true
              for (let I = 0; I < this.itemListing.length; I++) {
                this.itemListing[I].iSe = true
              }
            } else {
              this.selectAllCheck = false
            }
            if (this.isQueryAvailable) {
              this.selectAllCheck = true
              for (let index = 0; index < this.itemListing.length; index++) {
                if (this.itemListing[index].iSe === false) {
                  this.itemListing[index].iSe = true
                  this.dataBase.push(this.itemListing[index])
                }
              }
              setTimeout(() => {
                this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
                this.applyFilter(false)
              }, 100);
            } else {
              this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            }
            this.searchLoader = false
          },
          error => {
            this.resetPaginationValues = false
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.activeIndex === 1) {
        API.GetSKUSearchListing(
          this.searchVal,
          response => {
            this.isRefresh = true
            this.itemListing = response.Data === null ? [] : response.Data
            console.log(response.Data)
            if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
              for (let I = 0; I < this.itemListing.length; I++) {
                const findex = this.dataBase.findIndex(x => x.iC === this.itemListing[I].iC)
                console.log('findex', findex)
                if (findex > -1) {
                  this.itemListing[I].iSe = true
                }
              }
            } else if (this.searchVal === '' && this.dataBase.length === 0) {
              this.selectAllCheck = true
              for (let I = 0; I < this.itemListing.length; I++) {
                this.itemListing[I].iSe = true
              }
            } else {
              this.selectAllCheck = false
            }
            if (this.isQueryAvailable) {
              this.selectAllCheck = true
              for (let index = 0; index < this.itemListing.length; index++) {
                if (this.itemListing[index].iSe === false) {
                  this.itemListing[index].iSe = true
                  this.dataBase.push(this.itemListing[index])
                }
              }
              setTimeout(() => {
                this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
                this.applyFilter(false)
              }, 100);
            } else {
              this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            }
            this.searchLoader = false
            // if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
            //   for (let I = 0; I < this.itemListing.length; I++) {
            //     for (let J = 0; J < this.dataBase.length; J++) {
            //       if (this.dataBase[J].iC === this.itemListing[I].iC) {
            //         this.itemListing[I].iSe = true
            //       }
            //     }
            //   }
            // }
            // this.getListing()
          },
          error => {
            this.resetPaginationValues = false
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.activeIndex === 2) {
        API.GetASINSearchListing(
          this.searchVal,
          response => {
            this.isRefresh = true
            this.itemListing = response.Data === null ? [] : response.Data
            console.log(response.Data)
            if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
              for (let I = 0; I < this.itemListing.length; I++) {
                const findex = this.dataBase.findIndex(x => x.iC === this.itemListing[I].iC)
                console.log('findex', findex)
                if (findex > -1) {
                  this.itemListing[I].iSe = true
                }
              }
            } else if (this.searchVal === '' && this.dataBase.length === 0) {
              this.selectAllCheck = true
              for (let I = 0; I < this.itemListing.length; I++) {
                this.itemListing[I].iSe = true
              }
            } else {
              this.selectAllCheck = false
            }
            if (this.isQueryAvailable) {
              this.selectAllCheck = true
              for (let index = 0; index < this.itemListing.length; index++) {
                if (this.itemListing[index].iSe === false) {
                  this.itemListing[index].iSe = true
                  this.dataBase.push(this.itemListing[index])
                }
              }
              setTimeout(() => {
                this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
                this.applyFilter(false)
              }, 100);
            } else {
              this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            }
            this.searchLoader = false
            // if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
            //   for (let I = 0; I < this.itemListing.length; I++) {
            //     for (let J = 0; J < this.dataBase.length; J++) {
            //       if (this.dataBase[J].iC === this.itemListing[I].iC) {
            //         this.itemListing[I].iSe = true
            //       }
            //     }
            //   }
            // }
            // this.getListing()
          },
          error => {
            this.resetPaginationValues = false
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.activeIndex === 3) {
        API.GetOrderIdSearchValue(
          this.searchVal,
          0,
          100,
          response => {
            this.isRefresh = true
            this.itemListing = response.Data === null ? [] : response.Data
            console.log('this.dataBase', this.dataBase)
            console.log('this.searchVal', this.searchVal)
            if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
              for (let I = 0; I < this.itemListing.length; I++) {
                const findex = this.dataBase.findIndex(x => x.iC === this.itemListing[I].iC)
                console.log('findex', findex)
                if (findex > -1) {
                  this.itemListing[I].iSe = true
                }
              }
            } else if (this.searchVal === '' && this.dataBase.length === 0) {
              for (let I = 0; I < this.itemListing.length; I++) {
                this.itemListing[I].iSe = true
              }
              this.selectAllCheck = true
            } else {
              this.selectAllCheck = false
            }
            // if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
            //   for (let I = 0; I < this.itemListing.length; I++) {
            //     for (let J = 0; J < this.dataBase.length; J++) {
            //       if (this.dataBase[J].iC === this.itemListing[I].iC) {
            //         this.itemListing[I].iSe = true
            //       }
            //     }
            //   }
            // }
            // this.getListing()
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else {
        this.searchLoader = false
        this.isRefresh = true
      }
    },
    getListing (isFromZero) {
      this.isShowZeroSales = isFromZero
      this.firstLoading = false
      console.log('this.filterObject --->', this.filterObject)
      // let utcSTART1 = new Date(this.filterObject.startDate).toLocaleString("en-US", {
      //   timeZone: 'US/Pacific',
      //   hour12: false,
      //   year: 'numeric',
      //   month: '2-digit',
      //   day: '2-digit',
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit',
      //   timeZoneName: 'short',
      // })
      // let utcEND2 = new Date(this.filterObject.endDate).toLocaleString("en-US", {
      //   timeZone: 'US/Pacific',
      //   hour12: false,
      //   year: 'numeric',
      //   month: '2-digit',
      //   day: '2-digit',
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit',
      //   timeZoneName: 'short',
      // })
      // console.log('1111 utcSTART------------------>', utcSTART1)
      // console.log('22222 utcSTART------------------>', new Date(utcSTART1).toUTCString())
      // console.log('33333 utcSTART------------------>', moment.utc(new Date(utcSTART1)).format("YYYY-MM-DD HH:mm:ss"))
      console.log('utcSTART1', this.filterObject.startDate, 'utcEND2', this.filterObject.endDate);
      let utcSTART = moment(new Date(this.filterObject.startDate)).format("YYYY-MM-DD HH:mm:ss")
      let utcEND = moment(new Date(this.filterObject.endDate)).format("YYYY-MM-DD HH:mm:ss")
      console.log('utcSTART1', utcSTART, 'utcEND2', utcEND)
      // let temp1 = new Date(this.filterObject.startDate).toTimeString()
      // var utcSTART = temp1.toLocaleString("en-US", {
      //   timeZone: "America/Los_Angeles"
      // })
      // let temp2 = new Date(this.filterObject.endDate).toTimeString()
      // var utcEND = temp2.toLocaleString("en-US", {
      //   timeZone: "America/Los_Angeles"
      // })





      // let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      // let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      if (this.filterObject.sendItemId.length === 0) {
        this.filterObject.sendItemId = []
      }
      console.log('this.activeIndex', this.activeIndex)
      if (this.activeIndex === 0) {
        let TempArr = []
        API.GetNewAmazonSageReportListSageItemCode(
          utcSTART,
          utcEND,
          this.filterObject.sendItemId,
          false,
          this.isShowZeroSales,
          response => {
            TempArr = response.Data === null ? [] : response.Data
            let ValTotalQty = 0
            let ValTotalNet = 0
            let fbaInvQuantity = 0
            for (let index = 0; index < TempArr.length; index++) {
              ValTotalQty += TempArr[index].qtySold
              ValTotalNet += TempArr[index].totalNet
              fbaInvQuantity += TempArr[index].fbaInvQuantity
              TempArr[index].newAddCost = TempArr[index].avgSellingPrice * response.advertisingCostInPercentage / 100
              TempArr[index].newAvgUnitNet = TempArr[index].avgSellingPrice - (TempArr[index].stdCOGS + TempArr[index].avgMktFee + TempArr[index].avgFbaFee + TempArr[index].avgFbmFee + TempArr[index].avgInboundFee + (TempArr[index].avgSellingPrice * response.advertisingCostInPercentage / 100) )
            }
            this.reportListing = TempArr
            this.firstLoading = true
            this.tempArrA = response.Data === null ? [] : response.Data
            this.totalCountOfTotalNet = ValTotalNet
            this.totalCountOfQuntySold = ValTotalQty
            this.totalFBAQty = fbaInvQuantity
            this.adsCostOfTotalAdCostColumn = response.adsCostOfTotalAdCostColumn
            this.advertisingCostInPercentage = response.advertisingCostInPercentage
            this.totalSalesOfTotalSalesColumn = response.totalSalesOfTotalSalesColumn
            if (this.isSelectCustom === false) {
              for (let i = 0; i < this.buttonFiltersArray.length; i++) {
                if (this.selectedValue === this.buttonFiltersArray[i].value) {
                  this.selectedValueinText = this.buttonFiltersArray[i].title
                }
              }
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
            } else {
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
              this.getDateCountBetween(response.fromDate, response.toDate)
            }
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.activeIndex === 1) {
        let TempArr = []
        API.GetNewAmazonSageReportListSKU(
          utcSTART,
          utcEND,
          this.filterObject.sendItemId,
          false,
          this.isShowZeroSales,
          response => {
            TempArr = response.Data === null ? [] : response.Data
            let ValTotalQty = 0
            let ValTotalNet = 0
            let fbaInvQuantity = 0
            for (let index = 0; index < TempArr.length; index++) {
              ValTotalQty += TempArr[index].qtySold
              ValTotalNet += TempArr[index].totalNet
              fbaInvQuantity += TempArr[index].fbaInvQuantity
              TempArr[index].newAddCost = TempArr[index].avgSellingPrice * response.advertisingCostInPercentage / 100
              TempArr[index].newAvgUnitNet = TempArr[index].avgSellingPrice - (TempArr[index].stdCOGS + TempArr[index].avgMktFee + TempArr[index].avgFbaFee + TempArr[index].avgFbmFee + TempArr[index].avgInboundFee + (TempArr[index].avgSellingPrice * response.advertisingCostInPercentage / 100) )
            }
            this.reportListing = TempArr
            this.firstLoading = true
            this.tempArrA = response.Data === null ? [] : response.Data
            this.totalCountOfTotalNet = ValTotalNet
            this.totalCountOfQuntySold = ValTotalQty
            this.totalFBAQty = fbaInvQuantity
            this.adsCostOfTotalAdCostColumn = response.adsCostOfTotalAdCostColumn
            this.advertisingCostInPercentage = response.advertisingCostInPercentage
            this.totalSalesOfTotalSalesColumn = response.totalSalesOfTotalSalesColumn
            if (this.isSelectCustom === false) {
              for (let i = 0; i < this.buttonFiltersArray.length; i++) {
                if (this.selectedValue === this.buttonFiltersArray[i].value) {
                  this.selectedValueinText = this.buttonFiltersArray[i].title
                }
              }
              // this.fromApiStartDate = new Date(new Date(response.fromDate).setHours(0, 0, 0, 0)).toUTCString()
              // this.fromApiEndDate = new Date(new Date(response.toDate).setHours(23, 59, 0, 0)).toUTCString()
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
            } else {
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
              this.getDateCountBetween(response.fromDate, response.toDate)
            }
            
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.activeIndex === 2) {
        let TempArr = []
        API.GetNewAmazonSageReportListAsin(
          utcSTART,
          utcEND,
          this.filterObject.sendItemId,
          false,
          this.isShowZeroSales,
          response => {
            TempArr = response.Data === null ? [] : response.Data
            let ValTotalQty = 0
            let ValTotalNet = 0
            let fbaInvQuantity = 0
            for (let index = 0; index < TempArr.length; index++) {
              ValTotalQty += TempArr[index].qtySold
              ValTotalNet += TempArr[index].totalNet
              fbaInvQuantity += TempArr[index].fbaInvQuantity
              TempArr[index].newAddCost = TempArr[index].avgSellingPrice * response.advertisingCostInPercentage / 100
              TempArr[index].newAvgUnitNet = TempArr[index].avgSellingPrice - (TempArr[index].stdCOGS + TempArr[index].avgMktFee + TempArr[index].avgFbaFee + TempArr[index].avgFbmFee + TempArr[index].avgInboundFee + (TempArr[index].avgSellingPrice * response.advertisingCostInPercentage / 100) )
            }
            this.reportListing = TempArr
            this.firstLoading = true
            this.tempArrA = response.Data === null ? [] : response.Data
            this.totalCountOfTotalNet = ValTotalNet
            this.totalCountOfQuntySold = ValTotalQty
             this.totalFBAQty = fbaInvQuantity
            this.adsCostOfTotalAdCostColumn = response.adsCostOfTotalAdCostColumn
            this.advertisingCostInPercentage = response.advertisingCostInPercentage
            this.totalSalesOfTotalSalesColumn = response.totalSalesOfTotalSalesColumn
            if (this.isSelectCustom === false) {
              for (let i = 0; i < this.buttonFiltersArray.length; i++) {
                if (this.selectedValue === this.buttonFiltersArray[i].value) {
                  this.selectedValueinText = this.buttonFiltersArray[i].title
                }
              }
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
            } else {
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
              this.getDateCountBetween(response.fromDate, response.toDate)
            }
            
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    getDateCountBetween (startDate, endDate) {
      var date1 = new Date(startDate);
      var date2 = new Date(endDate);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      console.log('Difference_In_Time', Difference_In_Time,'----------', date2.getTime(), '-------------', date1.getTime())
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      this.dateDiffCount = Difference_In_Days === 0 ? 1 : Math.round(Difference_In_Days)
    },
    openFbaInv2 () {
      let routeData1 = this.$router.resolve({name: 'ASIN Sales'})
      window.open(routeData1.href, '_blank')
    },
    openFbaInv () {
      this.showFBAInventory = true
      setTimeout(() => {
        document.body.style = 'overflow: hidden;'
        this.$root.$emit('fbaInvData')
      }, 100);
    },
    report0Sales () {
      if (this.firstLoading && this.reportListing.length > 0 && !this.isShowZeroSales) {
        let utcSTART = moment(new Date(this.filterObject.startDate)).format("YYYY-MM-DD HH:mm:ss")
        let utcEND = moment(new Date(this.filterObject.endDate)).format("YYYY-MM-DD HH:mm:ss")
        if (this.activeIndex === 0) {
          this.filterObject.sendItemId = []
          for (let II = 0; II < this.dataBase.length; II++) {
            for (let JJ = 0; JJ < this.dataBase[II].productList.length; JJ++) {
              if (this.dataBase[II].productList[JJ].product_id !== 0) {
                this.filterObject.sendItemId.push(this.dataBase[II].productList[JJ].product_id)
              }
            }
          }
          this.openZeroDetail = true
          setTimeout(() => {
            document.body.style = 'overflow: hidden;'
            this.$root.$emit('callZeroSAGEITEMCODE', utcSTART, utcEND, this.filterObject)
          }, 100);
        } else if (this.activeIndex === 1) { 
          this.filterObject.sendItemId = []
          this.dataBase.forEach(a => {
            if (a.productId !== 0) {
              this.filterObject.sendItemId.push(a.productId)
            }
          })
          this.openZeroDetail = true
          setTimeout(() => {
            document.body.style = 'overflow: hidden;'
            this.$root.$emit('callZeroSKU', utcSTART, utcEND, this.filterObject)
          }, 100);
        } else if (this.activeIndex === 2) {
          this.filterObject.sendItemId = []
          for (let II = 0; II < this.dataBase.length; II++) {
            for (let JJ = 0; JJ < this.dataBase[II].productList.length; JJ++) {
              if (this.dataBase[II].productList[JJ].productId !== 0) {
                this.filterObject.sendItemId.push(this.dataBase[II].productList[JJ].productId)
              }
            }
          }
          this.openZeroDetail = true
          setTimeout(() => {
            document.body.style = 'overflow: hidden;'
            this.$root.$emit('callZeroASIN', utcSTART, utcEND, this.filterObject)
          }, 100);
        } else if (this.activeIndex === 3) {
          this.filterObject.sendItemId = []
          this.dataBase.forEach(a => {
            if (a.iC !== '') {
              let orderId = `'` + a.iC + `'`
              this.filterObject.sendItemId.push(orderId)
            }
          })
          this.openZeroDetail = true
          setTimeout(() => {
            document.body.style = 'overflow: hidden;'
            this.$root.$emit('callZeroRowData', utcSTART, utcEND, this.filterObject)
          }, 100);
        }
      }
      // if (this.firstLoading && this.reportListing.length > 0 && !this.isShowZeroSales) {
      //   this.isShowZeroSales = true
      //   if (this.activeIndex === 3) {
      //     this.isShowZeroSales = true
      //     this.applyFilter(true)
      //   } else {
      //     this.getListing(true)
      //   }
      // }
    },
    async exportReport () {
      if (this.firstLoading && this.reportListing.length > 0) {
        let utcSTART = moment(new Date(this.filterObject.startDate)).format("YYYY-MM-DD HH:mm:ss")
        let utcEND = moment(new Date(this.filterObject.endDate)).format("YYYY-MM-DD HH:mm:ss")
        if (this.filterObject.sendItemId.length === 0) {
          this.filterObject.sendItemId = []
        }
        let day1 = moment(this.filterObject.startDate).format("MM-DD-YY")
        let day2 = moment(this.filterObject.endDate).format("MM-DD-YY")
        let Time = ''
        let subURL = ''
        if (this.activeIndex === 0) {
          subURL = 'api/v1/analytics/amazon/sageItemReport'
          Time = 'AmazonSage Report-SAGE Item Code (' + day1 + '-To-' + day2 + ')'
        } else if (this.activeIndex === 1) {
          subURL ='api/v1/analytics/amazon/sageReport'
          Time = 'AmazonSage Report-SKU (' + day1 + '-To-' + day2 + ')'
        } else if (this.activeIndex === 2) {
          subURL = 'api/v1/analytics/amazon/sageASINReport'
          Time = 'AmazonSage Report-ASIN (' + day1 + '-To-' + day2 + ')'
        } else if (this.activeIndex === 3) {
          subURL = 'api/v1/analytics/amazon/sageRawData'
          Time = 'AmazonSage Report-Raw (' + day1 + '-To-' + day2 + ')'
          return this.downloadFileforRow(subURL, Time, utcSTART, utcEND)
        }
        let data = new FormData()
        data.append('fromDate', utcSTART)
        data.append('toDate', utcEND)
        data.append('productIds', this.filterObject.sendItemId)
        data.append('export', true)
        await this.downloadAttachment(subURL, `${Time}` + '.xlsx', data)
      }
    },
    async downloadFileforRow (subURL, Time, utcSTART, utcEND) {
      let data = new FormData()
      data.append('fromDate', utcSTART)
      data.append('toDate', utcEND)
      data.append('sku', '')
      data.append('sageItemCode', '')
      data.append('sku', '')
      data.append('asin', '')
      data.append('orderIds', this.filterObject.sendItemId)
      data.append('start', '')
      data.append('length', '')
      data.append('export', true)
      await this.downloadAttachment(subURL, `${Time}` + '.xlsx', data)
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    sortByColumn (i, val1, val2) {
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      if (this.pagination.descending) {
        this.reportListing.sort(function (a, b) {
          if (val2 === 'amazonSKU') {
            var nameA = a.amazonSKU
            var nameB = b.amazonSKU
          } else if (val2 === 'asin') {
            nameA = a.asin
            nameB = b.asin
          } else if (val2 === 'amazonDesc') {
            nameA = a.amazonDesc
            nameB = b.amazonDesc
          } else if (val2 === 'sageItemCode') {
            nameA = a.sageItemCode
            nameB = b.sageItemCode
          } else if (val2 === 'sageItemDesc') {
            nameA = a.sageItemDesc
            nameB = b.sageItemDesc
          } else if (val2 === 'qtySold') {
            nameA = a.qtySold
            nameB = b.qtySold
          } else if (val2 === 'fbaInvQuantity') {
            nameA = a.fbaInvQuantity
            nameB = b.fbaInvQuantity
          } else if (val2 === 'avgSellingPrice') {
            nameA = a.avgSellingPrice
            nameB = b.avgSellingPrice
          } else if (val2 === 'stdCOGS') {
            nameA = a.stdCOGS
            nameB = b.stdCOGS
          } else if (val2 === 'avgMktFee') {
            nameA = a.avgMktFee
            nameB = b.avgMktFee
          } else if (val2 === 'avgFbaFee') {
            nameA = a.avgFbaFee
            nameB = b.avgFbaFee
          } else if (val2 === 'avgAdCost') {
            nameA = a.avgAdCost
            nameB = b.avgAdCost
          } else if (val2 === 'avgInboundFee') {
            nameA = a.avgInboundFee
            nameB = b.avgInboundFee
          } else if (val2 === 'avgUnitNet') {
            nameA = a.avgUnitNet
            nameB = b.avgUnitNet
          } else if (val2 === 'totalSales') {
            nameA = a.totalSales
            nameB = b.totalSales
          } else if (val2 === 'totalCogs') {
            nameA = a.totalCogs
            nameB = b.totalCogs
          } else if (val2 === 'totalFbaFee') {
            nameA = a.totalFbaFee
            nameB = b.totalFbaFee
          } else if (val2 === 'totalMktFee') {
            nameA = a.totalMktFee
            nameB = b.totalMktFee
          } else if (val2 === 'totalAdCost') {
            nameA = a.totalAdCost
            nameB = b.totalAdCost
          } else if (val2 === 'totalInboundFees') {
            nameA = a.totalInboundFees
            nameB = b.totalInboundFees
          } else if (val2 === 'avgCogs') {
            nameA = a.avgCogs
            nameB = b.avgCogs
          } else if (val2 === 'lastCogs') {
            nameA = a.lastCogs
            nameB = b.lastCogs
          } else if (val2 === 'totalNet') {
            nameA = a.totalNet
            nameB = b.totalNet
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        this.reportListing.reverse(function (a, b) {
          if (val2 === 'amazonSKU') {
            var nameA = a.amazonSKU
            var nameB = b.amazonSKU
          } else if (val2 === 'asin') {
            nameA = a.asin
            nameB = b.asin
          } else if (val2 === 'amazonDesc') {
            nameA = a.amazonDesc
            nameB = b.amazonDesc
          } else if (val2 === 'sageItemCode') {
            nameA = a.sageItemCode
            nameB = b.sageItemCode
          } else if (val2 === 'sageItemDesc') {
            nameA = a.sageItemDesc
            nameB = b.sageItemDesc
          } else if (val2 === 'qtySold') {
            nameA = a.qtySold
            nameB = b.qtySold
          } else if (val2 === 'fbaInvQuantity') {
            nameA = a.fbaInvQuantity
            nameB = b.fbaInvQuantity
          } else if (val2 === 'avgSellingPrice') {
            nameA = a.avgSellingPrice
            nameB = b.avgSellingPrice
          } else if (val2 === 'stdCOGS') {
            nameA = a.stdCOGS
            nameB = b.stdCOGS
          } else if (val2 === 'avgMktFee') {
            nameA = a.avgMktFee
            nameB = b.avgMktFee
          } else if (val2 === 'avgFbaFee') {
            nameA = a.avgFbaFee
            nameB = b.avgFbaFee
          } else if (val2 === 'avgAdCost') {
            nameA = a.avgAdCost
            nameB = b.avgAdCost
          } else if (val2 === 'avgInboundFee') {
            nameA = a.avgInboundFee
            nameB = b.avgInboundFee
          } else if (val2 === 'avgUnitNet') {
            nameA = a.avgUnitNet
            nameB = b.avgUnitNet
          } else if (val2 === 'totalSales') {
            nameA = a.totalSales
            nameB = b.totalSales
          } else if (val2 === 'totalCogs') {
            nameA = a.totalCogs
            nameB = b.totalCogs
          } else if (val2 === 'totalFbaFee') {
            nameA = a.totalFbaFee
            nameB = b.totalFbaFee
          } else if (val2 === 'totalMktFee') {
            nameA = a.totalMktFee
            nameB = b.totalMktFee
          } else if (val2 === 'totalAdCost') {
            nameA = a.totalAdCost
            nameB = b.totalAdCost
          } else if (val2 === 'totalInboundFees') {
            nameA = a.totalInboundFees
            nameB = b.totalInboundFees
          } else if (val2 === 'avgCogs') {
            nameA = a.avgCogs
            nameB = b.avgCogs
          } else if (val2 === 'lastCogs') {
            nameA = a.lastCogs
            nameB = b.lastCogs
          } else if (val2 === 'totalNet') {
            nameA = a.totalNet
            nameB = b.totalNet
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
    clearSearch () {
      // this.selectAllCheck = false
      this.searchVal = ''
      this.getListingDetail()
    },
  },
  destroyed() {
  },
  beforeDestroy () {
    this.$root.$off("setNewTabActive");
    this.$root.$off("rowDataObject");
    this.$root.$off("closeZeroPopup");
    this.$root.$off("closeFBAInPopup");
  }
}
</script>
<style scoped>

.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.xmsmall_col {
  min-width: 50px !important;
  width: 50px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell_col {
  min-width: 70px !important;
  width: 70px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.loader2 {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #317ED2;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1.2s linear infinite;
}
.val1_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.val_col {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallC_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;

}
.small_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;

}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.mid_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.largeA_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.large_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallB_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallA_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.Xlarge_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.regular_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.desc_col {
  min-width: 390px !important;
  width: 390px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.lableWidth {
  width: 130px;
}
.dotwidth {
  width: 15px;
}
</style>
