<template>
  <div>
    <div class="bg-white cust_card h-full" style="position: fixed;width: 100%;min-width: 80px;z-index: 1000;top: 0;overflow-x: hidden;transition: 0.5s;border-radius: 0px !important;right:0;" v-if="firstLoading">
      <div class="rounded px-5 py-3  bg-primary2 text-white heading-2  flex items-center justify-between">
          <div class="flex items-center">
            <p class="text-white heading-4 pr-2 font-semibold">FBA Inventory</p>
          </div>
          <div class="flex gap-2">
            <div class=" text-white cursor-pointer hover:underline"  @click="exportReport()" v-if="firstLoading">
              <button  style="letter-spacing: 1px;" class=" px-3 py-1 cust_card heading-6 mr-1" :class="reportListing.length > 0 ? 'bg-white text-primary cursor-pointer' : 'cursor-not-allowed bg-gray-300 opacity-60 text-text2'">Export to Excel</button>
            </div>
            <div class=" text-white cursor-pointer hover:underline" @click="closePopup()">
              <button style="letter-spacing: 1px;" class="bg-white text-primary px-3 py-1 cust_card heading-6 "><i class="fas fa-times"></i></button>
            </div>
          </div>  
      </div>
      <div class="p-2">
        <div style="display:flex" class="w-96 text-text2 bg-white border border-gray-400 rounded-md px-2 py-1 items-center h-8 mt-1" >
          <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
          <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Search...."
            v-model="searchVal"
          >
          <span v-if="searchVal.length > 0" class="cursor-pointer" @click="clearSearch()">
            <i class="fas fa-times h-4 w-4 text-gray4"></i>
          </span>
        </div>
      </div>
      <div class="h-full p-1">
        <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder  relative mt-2 bg-white" v-if="firstLoading">
          <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6 py-1 items-center" style="position: sticky;top: 0;background: white;z-index: 10;">
              <div class="asin_col h-16 flex items-center sticky left-0 bg-white" >
                <span :class="titleObj[0].icon !== '' ? 'text-primary' : ''" class="cursor-pointer pl-2" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
                <span v-if="titleObj[0].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[0].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col h-16  flex items-center sticky bg-white" style="left: 190px !important;">
                <span class="cursor-pointer" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
                <span v-if="titleObj[1].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[1].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="large_col h-16  flex items-center sticky bg-white" style="left: 330px !important;">
                <span class="cursor-pointer" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
                <span v-if="titleObj[2].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[2].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="totalQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[3].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
                <span v-if="titleObj[3].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[3].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="defQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[4].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
                <span v-if="titleObj[4].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[4].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="expQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[5].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(5)">{{titleObj[5].title}}</span>
                <span v-if="titleObj[5].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[5].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="fulfilQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[6].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(6)">{{titleObj[6].title}}</span>
                <span v-if="titleObj[6].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[6].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="inWorkQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[7].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(7)">{{titleObj[7].title}}</span>
                <span v-if="titleObj[7].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[7].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="inShipQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[8].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(8)">{{titleObj[8].title}}</span>
                <span v-if="titleObj[8].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[8].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="inRecQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[9].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(9)">{{titleObj[9].title}}</span>
                <span v-if="titleObj[9].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[9].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="totResQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[10].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(10)">{{titleObj[10].title}}</span>
                <span v-if="titleObj[10].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[10].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[11].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(11)">{{titleObj[11].title}}</span>
                <span v-if="titleObj[11].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[11].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[12].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(12)">{{titleObj[12].title}}</span>
                <span v-if="titleObj[12].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[12].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="fcProQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[13].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(13)">{{titleObj[13].title}}</span>
                <span v-if="titleObj[13].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[13].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="totUnfQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[14].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(14)">{{titleObj[14].title}}</span>
                <span v-if="titleObj[14].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[14].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="custDamQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[15].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(15)">{{titleObj[15].title}}</span>
                <span v-if="titleObj[15].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[15].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[16].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(16)">{{titleObj[16].title}}</span>
                <span v-if="titleObj[16].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[16].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[17].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(17)">{{titleObj[17].title}}</span>
                <span v-if="titleObj[17].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[17].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="carDamQty h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[18].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(18)">{{titleObj[18].title}}</span>
                <span v-if="titleObj[18].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[18].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallD_col h-16  flex items-center text-right justify-end" >
                <span class="cursor-pointer" :class="titleObj[20].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(20)">{{titleObj[20].title}}</span>
                <span v-if="titleObj[20].icon === 'DESC'"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="titleObj[20].icon === 'ASC'"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
            </div>
            <div :style="`max-height:` + cardHight + `px !important;`" class="" v-if="reportListing.length > 0 && firstLoading">
              <div class="min-w-min group flex border-r border-l border-b p-2 border-gray1 bg-white text-sm items-center py-1  hover:bg-blue-50"  v-for="(value, indexA) in reportListing" :key="indexA">
                <div class="asin_col cursor-pointer sticky left-0 bg-white group-hover:bg-blue-50 flex items-center">
                  <div class="w-16">
                    <img v-if="value.asinImgThumbnail !== ''" class="border border-gray-100 rounded-xl bg-white" style="height: 42px;width:80px;object-fit: contain;" :src="value.asinImgThumbnail">
                    <img v-else class="border border-gray-100 rounded-xl px-2 bg-white" style="height: 40px;object-fit: contain;" src="@/assets/default.png">
                  </div>
                  <span class="heading-6-1 text-text1 pl-2 showOnly3Line" v-if="value.asin !== ''">{{value.asin}}</span>
                  <span class="heading-6-1 text-text1 pl-2" v-else>--</span>
                </div>
                <div class="small_col  flex items-center sticky bg-white group-hover:bg-blue-50" style="left: 190px !important;">
                  <span class="heading-6-1 text-text1" v-if="value.sku !== ''">{{value.sku}}</span>
                  <span class="heading-6-1 text-text1" v-else>--</span>
                </div>
                <div class="large_col sticky bg-white group-hover:bg-blue-50" style="left: 330px !important;">
                  <span class="heading-6-1 text-text1">
                    <span class="mb-0 whitespace-pre-line">
                      {{value.description === '' ? '--' : (value.description.length > 133)? value.isDescViewM ?  value.description : value.description.substr(0, 133) : value.description}}
                      <span v-if="value.description.length > 133" class="text-primary cursor-pointer heading-7" @click="value.isDescViewM = !value.isDescViewM">
                        {{value.isDescViewM === false ? 'Read More...' : 'Read Less...'}}</span>
                    </span>
                  </span>
                </div>
                <div class="totalQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvTotalQuantity}}</span>
                </div>
                <div class="defQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvDefectiveQuantity}}</span>
                </div>
                <div class="expQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvExpiredQuantity}}</span>
                </div>
                <div class="fulfilQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvFulfillableQuantity}}</span>
                </div>
                <div class="inWorkQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvInboundWorkingQuantity}}</span>
                </div>
                <div class="inShipQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvInboundShippedQuantity}}</span>
                </div>
                <div class="inRecQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvInboundReceivingQuantity}}</span>
                </div>
                <div class="totResQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvTotalReservedQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvPendingCustomerOrderQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvPendingTransShipmentQuantity}}</span>
                </div>
                <div class="fcProQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvFcProcessingQuantity}}</span>
                </div>
                <div class="totUnfQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvTotalUnfulfillableQuantity}}</span>
                </div>
                <div class="custDamQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvCustomerDamagedQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvWarehouseDamagedQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvDistributorDamagedQuantity}}</span>
                </div>
                <div class="carDamQty text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvCarrierDamagedQuantity}}</span>
                </div>
                <div class="smallD_col text-right justify-end">
                  <span class="heading-6-1 text-text1">{{value.fbaInvLastUpdatedTime !== '' ? value.fbaInvLastUpdatedTime : '--'}}</span>
                </div>
              </div>
            </div>
          </div>
            <div class="layout m-2" v-if="reportListing.length === 0 && firstLoading">
              <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  <p class=" font-medium heading-4">No record found.</p>
                </div>
              </div>
            </div>
            <div class="pt-2" v-show="firstLoading">
              <Pagination
                :defaultPerPage="50"
                :isShowOption="true"
                :totalDataCount="totalCount"
                :resetPagination="resetPaginationValues"
                @callApiAsPerPagination="getCustomerDataFromPagination"/>
            </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import API from '@/api/App.js'
import deboucneMixin from '@/mixins/debounce.js'
import Pagination from '@/View/components/rowDataPagination.vue'
export default {
  mixins: [deboucneMixin],
  components: {
    Pagination
  },
  data () {
    return {
      resetPaginationValues: false,
      totalCount: 0,
      reportListing: [],
      firstLoading: false,
      tabs: [
        {name: 'SAGE Item Code', active: true},
        {name: 'SKU', active: false},
        {name: 'ASIN', active: false},
        {name: 'Raw', active: false},
      ],
      searchVal: '',
      filterObject: {
        start: 0,
        length: 50,
        sortOrder: 'DESC',
        sortBy: 'fbaInvLastUpdatedTime',
      },
      titleObj: [
        {title: 'ASIN', icon: '', sortName: 'asin'},
        {title: 'SKU', icon: '', sortName: 'sku'},
        {title: 'Description', icon: '', sortName: 'description'},
        {title: 'Total Qty.', icon: '', sortName: 'fbaInvTotalQuantity'},
        {title: 'Defective Qty.', icon: '', sortName: 'fbaInvDefectiveQuantity'},
        {title: 'Expired Qty.', icon: '', sortName: 'fbaInvExpiredQuantity'},
        {title: 'Fulfilable Qty.', icon: '', sortName: 'fbaInvFulfillableQuantity'},
        {title: 'Inbound Working Qty.', icon: '', sortName: 'fbaInvInboundWorkingQuantity'},
        {title: 'Inbound Shipped Qty.', icon: '', sortName: 'fbaInvInboundShippedQuantity'},
        {title: 'Inbound Receiving Qty.', icon: '', sortName: 'fbaInvInboundReceivingQuantity'},
        {title: 'Total Reserved Qty.', icon: '', sortName: 'fbaInvTotalReservedQuantity'},
        {title: 'Pending Customer Order Qty.', icon: '', sortName: 'fbaInvPendingCustomerOrderQuantity'},
        {title: 'Pending Trans.Shipment Qty.', icon: '', sortName: 'fbaInvPendingTransShipmentQuantity'},
        {title: 'FC Processing Qty.', icon: '', sortName: 'fbaInvFcProcessingQuantity'},
        {title: 'Total Unfulfillable Qty.', icon: '', sortName: 'fbaInvTotalUnfulfillableQuantity'},
        {title: 'Customer Damaged Qty.', icon: '', sortName: 'fbaInvCustomerDamagedQuantity'},
        {title: 'Warehouse Damage Qty.', icon: '', sortName: 'fbaInvWarehouseDamagedQuantity'},
        {title: 'Distributor Damaged Qty.', icon: '', sortName: 'fbaInvDistributorDamagedQuantity'},
        {title: 'Carrier Damaged Qty.', icon: '', sortName: 'fbaInvCarrierDamagedQuantity'},
        {title: 'Product Name', icon: '', sortName: 'fbaInvProductName'},
        {title: 'Last Updated Time', icon: 'DESC', sortName: 'fbaInvLastUpdatedTime'},
        
      ],
      dataBase: [],
      cardHight: 0,
      mainHeight: 0,
    }
  },
  props: [],
  created() {
  },
  mounted () {
    this.$root.$on('fbaInvData', () => {
      this.apiCall()
    })
    this.cardHight = window.innerHeight - 310
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    document.title = 'COGS ◾ Amazon Sage'
  },
  watch: {
    searchVal: {
      handler() {
        this.resetPaginationValues = true
        this.apiCall()
      }
    },
  },
  methods: {
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.apiCall()
    },
    clearSearch () {
      this.resetPaginationValues = true
      this.searchVal = ''
      this.apiCall()
    },
    apiCall () {
      if (this.searchVal === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      }
      API.GetFBAInventoriesList(
          this.filterObject.start,
          this.filterObject.length,
          this.searchVal,
          this.filterObject.sortBy,
          this.filterObject.sortOrder,
          false,
          response => {
            this.totalCount = response.Data.count
            this.resetPaginationValues = false
            let TempArr = []
            TempArr = response.Data.tableRow === null ? [] : response.Data.tableRow
            for (let index = 0; index < TempArr.length; index++) {
              TempArr[index].isDescViewM = false
            }
            this.reportListing = TempArr
            this.firstLoading = true
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
    },
    closePopup () {
      this.$root.$emit('closeFBAInPopup', false)
    },
    sortByColumn (index) {
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.apiCall()
    },
    async exportReport () {
      if (this.firstLoading && this.reportListing.length > 0) {
        let Time = ''
        let subURL = 'api/v1/analytics/amazon/getFBAInventories'
        Time = 'SKU - FBA Inventory'
        let data = new FormData()
        data.append('start', 0)
        data.append('length', 10)
        data.append('searchValue', '')
        data.append('sortColumnName', 'fbaInvLastUpdatedTime')
        data.append('sortColumnOrder', 'DESC')
        data.append('export', true)
        await this.downloadAttachment(subURL, `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
  },
  destroyed() {
  },
  beforeDestroy () {
     this.$root.$off('paginationData')
     this.$root.$off('fbaInvData')
  }
}
</script>
<style scoped>

.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.xmsmall_col {
  min-width: 70px !important;
  width: 70px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.avgSell_col {
  min-width: 80px !important;
  width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.val1_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.val_col {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallC_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

}
.small_col {
  min-width: 140px !important;
  width: 140px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

}
.asin_col {
  min-width: 190px !important;
  width: 190px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.mid_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.largeA_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.large_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallB_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.smallD_col {
  min-width: 170px !important;
  width: 170px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;

}
.smallA_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.Xlarge_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.regular_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.desc_col {
  min-width: 210px !important;
  width: 210px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.lableWidth {
  width: 130px;
}
.dotwidth {
  width: 15px;
}
.defQty {
  min-width: 89px !important;
  width: 89px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.expQty {
  min-width: 75px !important;
  width: 75px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.totalQty {
  min-width: 62px !important;
  width: 62px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.fulfilQty {
  min-width: 88px !important;
  width: 88px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.inWorkQty {
  min-width: 144px !important;
  width: 144px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.inShipQty {
  min-width: 138px !important;
  width: 138px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.inRecQty {
  min-width: 151px !important;
  width: 151px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.totResQty {
  min-width: 121px !important;
  width: 121px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.fcProQty {
  min-width: 111px !important;
  width: 111px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.totUnfQty {
  min-width: 142px !important;
  width: 142px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.custDamQty {
  min-width: 155px !important;
  width: 155px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.carDamQty {
  min-width: 135px !important;
  width: 135px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
</style>